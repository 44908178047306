export const bgTranslations = {
    sidemenu: {
        alergens: "Алергени",
        dashboard: "Начало",
        menu: "Меню",
        categories: "Категории",
        categoriesHierarchy: "Категории",
        subcategories: "Подкатегории",
        products: "Продукти",
        administration: "Админ",
        tables: "Маси",
        orders: "Поръчки",
        all_orders: "Всички поръчки",
        orders_from_spot: "От обекта",
        orders_delivery: "С доставка",
        orders_preorder: "С взимане от място",
        orders_room: "От стая",
        deleted_orders: "Всиички /изтрити/",
        charts: "Справки",
        reservations: "Резервации",
        locations: "Заведения",
        rooms: "Зали",
        reservation_tables: "Рез. маси",
        schedule: "График",
        settings: "Настройки",
        users: "Потребители",
        system: "Системни",
        super_admin: "Супер админ",
        current_place: "Текущ обект",
        clients: "Клиенти",
        place: "Обект",
        places: "Обекти",
        ingredients: "Съставки",
        statistics: "Статистики",
        statistics_users: "С-ки Потребители",
        statistics_orders: "С-ки Поръчки",
        delivery_regions: "Региони за доставка",
        feedback: "Оценки",
        feedback_questions: "Оценки - въпроси",
        feedback_answers: "Оценки - отговори",
        work_users: "Служебни",
        registered_users: "Регистрирани",
        invoices: "Фактури",
        auto_invoices: "Автоматични Фактури",
        proforms: "Проформи",
        bookings: "Bookings",
        promocodes: "Промокод",
        general_promotions: "Общи реклами",
        place_images: "Галерия"
    },
    common: {
        notification: 'Нотификации',
        notifications: 'Нотификации',
        create: 'Създай',
        show_cat_only_for_spot_orders_text: "Покажи само за поръчка от място",
        hide_cat_only_for_spot_orders_text: "Скрии само за поръчка от място",
        is_paying: "Плащащ",
        next: 'Следващ',
        count_promo_codes: 'Брой промокодове',
        ordertype: 'Тип на поръчка',
        promocode: 'Промокод',
        discount_value: 'Отстъпка - стойност',
        discount_type: 'Отстъпка - тип',
        valid_from: 'Валидна от',
        valid_to: 'Валидна до',
        from: 'От',
        to: 'До',
        discount: 'Отстъпка',
        valid: 'Валидност',
        used: 'Използван',
        not_used: 'Неизползван',
        months: {
            1: 'Януари',
            2: 'Февруари',
            3: 'Март',
            4: 'Април',
            5: 'Май',
            6: 'Юни',
            7: 'Юли',
            8: 'Август',
            9: 'Септември',
            10: 'Октомври',
            11: 'Ноември',
            12: 'Декември',
        },
        completed: 'Приключен',
        prefix: "Префикс",
        place_in_url: 'Добави името на обекта в линка към QR кода',
        number_start: 'Номер от',
        number_end: 'Номер до',
        add_bulk: "Добавяне на много",
        no_internet: 'Липсва връзка с интернет',
        day_1: 'Понеделник',
        day_2: 'Вторник',
        day_3: 'Сряда',
        day_4: 'Четвъртък',
        day_5: 'Петък',
        day_6: 'Събота',
        day_7: 'Неделя',
        max_count_addable_ingredients: 'Максимален брой',
        min_count_addable_ingredients: 'Минимален брой',
        hide_variants: 'Скрий вариантите на продукта, на страницата за преглед на всички продукти',
        active_in: 'Активна в:',
        warning: 'Внимание',
        info: 'Информация',
        color: "Цвят",
        back: "Назад",
        included: 'вкл.',
        link: 'Линк',
        box: "Кутия",
        boxes: "Кутии",
        done: "Готово",
        background: "Фон",
        color: "Цвят",
        title: "Заглавие",
        paid: "Платена",
        waitings: "Чакащи",
        order: "Поръчка",
        alergens: "Алергени",
        id: "Номер",
        dashboard: "Начало",
        category_name: "Име на категорията",
        product_name: "Име на продукта",
        name: "Име",
        short_name: "Кратко име",
        icon: "Икона",
        status: "Статус",
        action: "Действие",
        category: "Категория",
        client: "Клиент",
        subcategory: "Подкатегория",
        size: "Размер",
        price: "Цена",
        is_drink: "Напитка",
        number: "Номер",
        order_number: "Номер поръчка",
        order_type: "Тип поръчка",
        date: "Дата",
        turnover: "Оборот",
        code: "Код",
        active_text: "Активен",
        active: "Активна",
        inactive: "Неактивна",
        description: "Описание",
        image: "Снимка",
        main_image: "Основна Снимка",
        background_image: "Фонова Снимка",
        yes: "Да",
        no: "Не",
        add: "Добави",
        cancel: "Откажи",
        save: "Запази",
        delete: "Изтрий",
        close: "Затвори",
        edit: "Редактирай",
        price_of_variant: "Цена на вариант",
        table: "Маса",
        room: "Стая",
        bill_wanted: "Поискана сметка",
        bill: "Сметка",
        currency: "лв.",
        download: "Свали",
        value: "Стойност",
        images: "Снимки",
        rows: "Редове",
        type: "Тип",
        restaurant_order: "Поръчка от ресторант",
        delivery: "Доставка",
        preorder: "Предварителна поръчка",
        delivery_and_preorder: "Доставка и Предварителна поръчка",
        restaurant_and_delivery: "Поръчка от ресторанта и доставка",
        restaurant_and_preorder: "Поръчка от ресторанта и Предварителна поръчка",
        others: "Други",
        role: "Роля",
        additional_price: "Цена на кутия",
        slug: "Slug",
        cash: "В брой",
        card: "С карта",
        on_room_bill: "На стая",
        level: "Ниво в йерархията",
        parent_category: "Категория родител",
        filter: "Филтър",
        place: "Обект",
        activeDaysOfWeek: {
            1: "Пон",
            2: "Вт",
            3: "Ср",
            4: "Четв",
            5: "Пет",
            6: "Съб",
            7: "Нед",
        },
        types: {
            default: 'По подразбиране',
            image: 'Снимкова',
            info: 'Информационна',
            link: 'Линк',
            booking: 'Booking'
        },
        open_call_waiterCat: "Отвори 'Повирай Сервитьор'",
        activeStartTime: "Активно време начало",
        activeEndTime: "Активно време край",
        is_promo_product: "Промо продукт",
        is_promo_product_info: "Изскача като попъп за дадена категория. След като се активира може да бъде задавано от страницата за промяна на категория в таб 'Промо продукти за категория'",
        is_image_cat: "Снимкова категория",
        priority: "Приоритет",
        priority_description: "Колкото по-малка е цифрата, толкова по-напред се показва (1 - първи, 2 - и т.н.)",
        start_date: "Начална дата",
        end_date: "Крайна дата",
        add_row: "Добави ред",
        remove_row: "Премахни ред",
        delivery_price: "Цена на доставка",
        price_over: 'Над (цена)',
        price_over_info: "Цена на поръчка, над която ще се приложи цената",
        rotation_start_date: "Начална дата на ротация",
        rotation_start_date_info: "Категорията ще се показва в менюто на ротационен принцип на всеки {Дни на ротация} от началната дата",
        rotation_days_count: "Дни на ротация",
        rotation_days_count_info: "Броят дни през които да се показва категорията",
        order_allowed: "Позволена поръчка",
        order_allowed_info: "Позволява/забранява поръчки за дадената категория. (Ако има продукт в съответната категория, който присъства в повече категории, той ще може да се поръчва, ако на другата категория не са забраненти поръчките)",
        hide_for_ordering: "Скрий продукт",
        hide_for_ordering_info: "Скрива продукта от менюто",
        fast_order_info: "Управлявайте видимостта на банера от Настройки > Банери",
        image_scale: "Приближеност на снимка",
        discount_percent: "Процент отстъпка",
        discount_money: "Левова отстъпка",
        discount_percent_info: "Задава процент отстъпка на всички продукти в тази категория",
        deleted: "Изтрит",
        isLiquid: "Напитка",
        label: "Надпис",
        room_number: "Номер на стая",
        remove_current_worktime: "Маркирай като Неработен",
        save_current_worktime_for_whole_week: "Задай това работно време, за цялата седмица",
        question: "Въпрос",
        answer: "Отговор",
        approved: "Одобрен",
        not_approved: "Неодобрен",
        user: "Потребител",
        orders: "Поръчки",
        total_amount: "Общо",
        booking_duration: "Booking продължителност",
        table_or_room: "Маса/Стая",
        value_type: "Тип",
        active_start_time: "Активно от",
        active_end_time: "Активно до",
        on_order_number: "Номер поръчка, на която да се активира отстъпката",
        time_until_order_ready: "Време докато е готова",
        ws_code: "Код в СС",
        with_ws_code: "С код",
        without_ws_code: "Без код",
        productPrintGroupId: "Принт група (ВНИМАНИЕ)",
        choose_product_print_group: "Моля изберете принт група (ВНИМАНИЕ)",
        show_only_for_spot_browse: "Покажи само за разглеждане от място (скрива се за поръчки за доставка, взимането от място и т.н.)",
        hide_only_for_spot_browse: "Скрий само за разглеждане от място (показва се за поръчки за доставка, взимането от място и т.н.)",
        show_cat_only_for_delivery_orders_text: "Покажи само за доставка",
        hide_cat_only_for_delivery_orders_text: "Скрий само за доставка",
        external_system_region_id: "ID на регион във външна система",
        city: 'Град',
        hour: 'Час',
        payment_type: 'Начин на плащане',
    },
    versions: {
        features_about_version: "Нови функционалности за версия:",
        version: "Версия"
    },
    alergens: {
        title: "Алергени",
        add: "Добави алерген",
        edit: "Промяна на алергени",
        delete: {
            title: "Изтрий алерген",
            confirm: "Сигурен ли си, че искаш да изтриеш алергена "
        }
    },
    places: {
        title: "Обекти",
        add: "Добави обект",
        edit: "Промяна на обект",
        ordertypes: "Типове поръчка",
        edit: "Промяна",
        phone: "Тел. Номер за СМС известяване при невидяни на поръчки *",
        delete: {
            title: "Изтрий обект",
            confirm: "Сигурен ли си, че искаш да изтриеш обекта "
        },
        copy_menu_from: "Копиране на меню от:",
        init_settings_for_all_places: "Инициализирай настройките за всички обекти",
        functionality_modules: "Функционални модули",
        sms_logs: "SMS-и по месеци",
        additional_info: "Допълнителна информация (договорки)",
        company_email: "Имейл",
        lat: "Lat",
        lng: "Lng",
        visible_on_map: "Видимо на картата",
        city: "Град",
        tags: "Тагове (за филтриране на map страницата)",
    },
    categories: {
        title: "Категории",
        description: "Описание",
        add: "Добави категория",
        edit: "Промяна на категория",
        delete: {
            title: "Изтрий категория",
            confirm: "Сигурен ли си, че искаш да изтриеш категорията "
        },
        tabs: {
            edit: "Промяна",
            promo_products_for_category: "Промо продукти за категория",
            images_category: "Снимкова категория",
            booking: "Booking",
            video: "Видео"
        },
        places_for_category: "Обекти, за които да се показва категорията",
        promo_title: "Заглавие на попъп с промоция",
        booking_start_work_time: "Начало на работно време за booking (напр. 10:00)",
        booking_end_work_time: "Край на работно време за booking (напр. 18:00)",
    },
    subcategories: {
        title: "Подкатегории",
        add: "Добави подкатегория",
        edit: "Промяна на подкатегория",
        delete: {
            title: "Изтрий подкатегория",
            confirm: "Сигурен ли си, че искаш да изтриеш подкатегорията "
        }
    },
    products: {
        replace_image: "Смени снимката",
        upload_image: "Качи снимка",
        fast_order: "Видим в банер за Бърза поръчка",
        title: "Продукти",
        title_single: "Продукт",
        ingredients: "Съставки",
        variants: "Варианти",
        add: "Добави продукт",
        add_bulk: "Добавяне на много продукти",
        edit: "Запази продукт",
        add_variant: "Добави вариант",
        save_variant: "Запази вариант",
        delete_image: "Изтрий снимката",
        delete: {
            title: "Изтрий продукт",
            confirm: "Сигурен ли си, че искаш да изтриеш продукта "
        },
        related_products: "Свързани продукти",
        related_products_info: "Когато бъде добавен в количката продукта, 'Свързаните продукти' изскачат като попъп с цел upselling (Клиента добавя бургер, изскача му попъп с предложение за картофки)",
        bulk_update_products_field: "Промяна на много продукти",
        bulk_update_products_field_info: "Избраното поле на всички филтрирани продукти ще бъде променено със зададената стойност. Да се използва с внимание!",
        ws_code: "Код в СС",
        ws_code_text: "Код на продукта в складовата система",
        sync_products: "Синхронизирай със складовата",
        sync_products_confirmation: "Това ще синхронизира имената и цените на продуктите в менюто с тези в складовата ви система. Сигурни ли сте?",
        show_only_for_spot_browse: "Покажи само за разглежда от място",
        hide_only_for_spot_browse: "Скрий само за разглеждане от място",
        comment_placeholder: "Коментар - placeholder",
    },
    product_variants: {
        delete: {
            title: "Изтрий вариант",
            confirm: "Сигурен ли си, че искаш да изтриеш варианта "
        },
        ws_code_text: "Код на варианта в складовата система. Взима превес пред код на продукта в СС",
    },
    ingredients: {
        addable: "Добавящи се съставки",
        removable: "Премахващи се съставки",
        required: "Задължителни съставки",
        title: "Съставки",
        add: "Добави",
        edit: "Промяна на съставка",
    },
    orders: {
        activete_sound_dialog_title: 'Този екран съдържа известяване чрез звук и вибрация(за мобилни телефони)',
        activete_sound_dialog_description: '',
        select_tables: "Изберете маси",
        no_data: "Няма данни в избрания сектор",
        hide_deleted: "Скрии изтрити",
        show_deleted: "Виж изтрити",
        hide_completed: "Скрии завършени",
        show_completed: "Виж завършени",
        client_name: "Име на клиент",
        phone: "Телефон",
        client_phone: "Телефон на клиент",
        client_address: "Адрес на клиент",
        products: "Продукти",
        order_date: "Време на поръчка",
        order_date_short: "Поръчана в",
        update_date: "Последна промяна",
        update_date_short: "Промяна в",
        type: "Тип поръчка",
        user_needs: "Желания на клиента",
        payment_type: "Тип плащане",
        serve_time: "Занеси в: ",
        client_pickup_time: "Ще вземе в: ",
        client_delivery_time: "Доставка в: ",
        room_number: "Номер на стая: ",
        select_table_regions: "Изберете региони маси",
        //'ordered','cooked_spot','delivered_spot','cooked_delivery','on_the_way_delivery','cooked_preorder','paid'

        user_needs_list: {
            "clean_table": "Почистете масата",
            "ice": "Лед",
            "napkins": "Салфетки",
            "new_cutlery": "Нови прибори",
            "change_ashtray": "Смяна на пепелник",
            "other": "Друго"
        },

        types: {
            delivery: "С ДОСТАВКА",
            preorder: "С ВЗИМАНЕ ОТ МЯСТО",
            spot: "От обекта",
            room: "От стая",
            tablet_mode: "Режим таблет",
            served_on_spot: "Сервирана на място"
        },

        statuses: {
            ordered: "Поръчана",
            cooked_spot: 'Приготвена',
            cooked_room: 'Приготвена',
            served_spot: 'Сервирана',
            cooked_delivery: 'Приготвена',
            on_the_way_delivery: 'Доставя се',
            cooked_preorder: 'Приготвена',
            cooked_room: "Приготвена",
            paid: "Платена",
            rejected: "Откажи поръчката"
        },

        all: "Всички",
        new: "Нови",
        ordered: "Нови",
        cooked: "Приготвени",
        served: "Сервирани",
        for_delivering: "За доставяне",
        delivering: "Доставящи се",
        waiting_for_pickup: "Чакащи клиент",
        paid: "Платени",
        future: "Бъдещи",
        delivered: "Доставени",

        delete: {
            title: "Изтрий поръчка",
            confirm: "Сигурен ли си, че искаш да изтриеш поръчка # "
        },
        delivery_date_short: "Доставка в",
        pickup_date_short: "Взимане в",
        connected_to_printer: "СВЪРЗАН С ПРИНТЕР",
        connect_to_printer: "СВЪРЖИ С ПРИНТЕР",
        connect_to_mini_comp: "СВЪРЖИ С МИНИ КОМП.",
        connected_to_mini_comp: "СВЪРЗАН С МИНИ КОМП.",
        push_notifications: "Push нотификации",
        delete_all: "Изтрии всички за днес",
        print_all_today: "Принт всички от днес",
        warehouse_errors_modal_title: "Складова система - грешка" 
    },
    messages: {
        errors: {
            invalid_code: 'Невалиден код',
            invalid_name: "Невалидно име",
            invalid_link: "Невалиден линка",
            invalid_category: "Невалидна категория",
            invalid_subcategory: "Невалидна подкатегория",
            invalid_size: "Невалиден размер",
            invalid_price: "Невалидна цена",
            invalid_table_num: "Невалиден номер на маса",
            invalid_table_num_start: "Невалиден начален номер на маса",
            invalid_table_num_end: "Невалиден краен номер на маса",
            invalid_email: "Невалиден мейл",
            invalid_role: "Невалидна роля",
            you_must_select_place: "Трябва да изберете обект",
            no_data_to_insert: "Празни данни",
            invalid_price_over: "Невалидна цена над",
            invalid_crop_image: "Трябва да качите и изрежете картинката",
            invalid_slug: "Невалиден slug",
            invalid_ordertype: "Невалиден тип поръчка",
            invalid_place: "Невалиден обект",
            invalid_credentials: "Невалидни данни за вход",
            reached_max_rows_save_to_proceed: 'Достигнахте лимита от редове. Моля запазете текущите, за да продължите.',
            sound_not_found: 'Звуковото известяване не е открито. Няма да бъдете уведомени при нови поръчки. Свържете се със отдел поддръжка или презаредете страницата!',
            invalid_start_work_time: 'Невалидно начало на работно време',
            invalid_end_work_time: 'Невалидно край на работно време',
            invalid_min_people_count: "Невалиден мин. брой хора",
            invalid_max_people_count: "Невалиден макс. брой хора",
            invalid_tables: "Трябва да изберете маси",
            min_people_must_be_less_than_max_people: "Мин. брой хора трябва да е по-малък от макс. брой хора",
            invalid_city: "Трябва да изберете град",
            invalid_phone: "Въведете телефон, на който да се изпраща смс при невидяна поръчка. 359..... или въведете '359' САМО ако обекта е тестов!",
            radius_end_must_be_bigger_than_radius_start: "Краят на радиуса, трябва да е по-голям от началото",
            radius_end_required: "Радиус край е задължителен",
            radius_start_required: "Радиус начало е задължителен",
        }
    },
    tables: {
        title: "Маси",
        add: "Добави маса",
        edit: "Промяна на маса",
        delete: {
            title: "Изтрий маса",
            confirm: "Сигурен ли си, че искаш да изтриеш маса "
        },
        x: 'Координати Х',
        y: 'Координати Y',
        w: 'Размер W',
        h: 'Размер H',
        generate_qr: "Генерирай нов QR код",
        is_for_reservation: "Активна за резервация",
        reservation_min_people: "Мин. бр. хора за резервация",
        reservation_max_people: "Макс. бр. хора за резервация",
        restaurant_hall: "Зала",
        ordertype: "Тип поръчка",
        table_region: "Регион",
        is_for_app: "За меню приложение - маса която се използва при логин в меню апп",
        is_kiosk_mode: "За киоск режим - маса за поръчка от киоск",
    },
    promocodes: {
        title: 'Промокод',
        add: 'Добави промокод',
        edit: 'Редактиране на промокод'
    },
    settings: {
        menu: "Меню",
        kitchen: "Kitchen system",
        admin: "Админ",
        general: "Общи настройки",
        background_image: "Фонова картинка",
        logo: "Лого",
        default_lang: {
            default: "Език на менюто по подразбиране",
            bg: 'Български',
            en: 'Английски',
            ro: 'Румънски',
            es: 'Испански'
        },
        first_init_lang: {
            default: "Първоначален превод на менюто при зареждане на страницата - Google translate",
            without_initial_translate: "Без първоначален превод при зареждане на страницата",
            bg: 'Български',
            en: 'Английски',
            ro: 'Румънски',
        },
        translations_type: {
            default: "Тип на превод в менюто",
            visible_description: "- При Google Translate превода става автоматично. <br/><br/>- При стандартния превод, за да се отразят различните преводи на менюто при смяна на език е необходимо да се преведат ръчно всички категории,продукти..",
            1: "Google Translate",
            2: "Стандартен превод",
        },
        change_language_position: {
            default: "Позиция на бутона за смяна на езика в менюто",
            visible_description: "1",
            header: "Горна част",
            footer: "Долна част",
        },
        default_currency_suffix: {
            default: "Валута",
            visible_description: "123",
        },
        view_product_page: {
            default: "Страница за преглед на продукт",
            0: "Не",
            1: "Да - без иконка за добавяне на продукт",
            2: "Да - с иконка за добавяне на продукт",
        },
        place_address: {
            default: "Адрес на обекта",
            visible_description: "Използва се и за навигацията при създаване на поръчки от тип: 'Взимане от място'",
        },
        call_btn_instead_of_search: {
            default: "Телефонен номер",
            visible_description: "Ако полето е празно се показва бутона за търсачката",
        },
        second_call_btn: {
            default: "Втори телефонен номер (Не е задължително)",
            visible_description: "Ако полето е празно се показва само първият телефонен номер или бутона за търсачката",
        },
        show_variant_additional_price: {
            default: "Позволи задаване на Цена на кутия за варинат /За доставка и взимане от място/",
            0: "Не",
            1: "Да",
        },
        meta_title_place_name: {
            default: "Име на обекта",
            visible_description: "meta title: 'Името на обекта - Дигитално меню от TabL'",
        },
        meta_description: {
            default: "Описание на обекта /2-3 изр./",
            visible_description: "meta description",
        },
        about_page_description: {
            default: "Страница за обекта: Описание (не е задължително)",
        },
        about_page_working_time: {
            default: "Страница за обекта: Работно време",
            visible_description: "",
        },
        about_page_delivery_prices: {
            default: "Страница за обекта: Доставка/Цени",
            visible_description: "",
        },
        about_page_payment_types: {
            default: "Страница за обекта: Видове плащания",
            visible_description: "",
        },
        about_page_title: {
            default: "По-кратко име на обекта (Не е задължително)",
            visible_description: "Използва се, ако името на обекта е много дълго и се визуализира в меню-то (бутона в горната лява част)"
        },
        about_restaurant_page_title_in_left: {
            default: "Страница за ресторанта - Премести името на ресторанта над логото (Използва се, ако описанието на ресторанта е по-дълго)",
            0: "Не",
            1: "Да",
        },
        order_button_on_product_variant: {
            default: "Показване на бутон 'поръчай' и 'коментар' при вариантите на продукта.",
            0: "Не (само варианта)",
            1: "Да - Показване на варианта, бутон добави и бутон коментар/отвори (Бутона коментар/отвори е видим само ако има опция за поръчка)",
            2: "Да - Показване на варианта и бутон добави",
        },
        show_liquid_on_non_liquid_view_product_page: {
            default: "Генериране на случайни продукти в секция 'ДРУГИ ПРЕДЛОЖЕНИЯ' на страницата за преглед на продукт",
            0: "Да",
            1: "Не"
        },
        menu_background_image: {
            default: "Фонова снимка за менюто /Не е видима на страницата за преглед на категории, ако са тип Снимка/"
        },
        logo: {
            default: "Вашето лого"
        },
        tabl_logo: {
            default: "TabL лого"
        },
        subcategories_design: {
            default: "Дизайн на подкатегориите",
            1: "Снимки",
            2: "Редове",
            3: "В две колони със снимки"
        },
        split_header_category_subcategory_on_two_rows: {
            default: "Разделяне на категориите на 2 реда, ако има празно пространство между думите",
            0: "Не",
            1: "Да"
        },
        order_email_visible: {
            default: "Показване на полето за Email при поръчка",
            visible_description: "Не важи за поръчки от ресторант - там само се сканира",
            0: "Не",
            1: "Да"
        },
        promocode_visible: {
            default: "Показване на полето за Промокод при поръчка",
            visible_description: "Не важи за поръчки от ресторант - там само се сканира",
            0: "Не",
            1: "Да"
        },
        disable_overtime_orders: {
            default: "Приемане на поръчки САМО в работно време",
            0: "Не",
            1: "Да"
        },
        hide_products_for_ordering_with_token: {
            default: "Активиране на възможността за скриване на продукти за поръчване /Необходимо е всеки продукт да се отбележи като такъв/",
            0: "Не",
            1: "Да"
        },
        ask_for_bill_hidden: {
            default: "Скриване на Опцията за искане на сметка след Поръчка от място",
            0: "Не",
            1: "Да"
        },
        user_needs_position: {
            default: "Позиция на бутон 'Повикай сервитьор'",
            header: "Горна част - меню",
            footer: "Долна част"
        },
        hide_order_delivery_pickup_date: {
            default: "Скриване на Опцията за избор на час при поручка с Доставка/Взимане от място",
            0: "Не",
            1: "Да"
        },
        hide_order_room_date: {
            default: "Скриване на Опцията за избор на час при поръчка от Стая",
            0: "Не",
            1: "Да"
        },
        minimal_order_price: {
            default: "Задаване на минимална стойност на поръчката",
        },
        validate_phone_number_regex: {
            default: "Regex за валидация на телефонен номер",
        },
        close_order_modal_after_order: {
            default: "Затваряне на прозореца с поръчките, след поръчка",
            0: "Не",
            1: "Да"
        },
        working_hours: {
            default: "Работно време на заведението",
        },
        second_working_hours: {
            default: "Работно време след почивката (ако има такава по средата на деня)",
        },
        min_price_free_delivery: {
            default: "Минимална цена на поръчка, над която доставкта е безплатна"
        },
        delivery_price: {
            default: "Цена на доставка, ако поръчката е на стойност по-ниска от минималната за безплатна доставка. /Калкулира се в цената/"
        },
        order_active_statuses: {
            default: "Управление на видимите статуси на различните типове поръчки",
            visible_description: "Сложи 0 на статусите, които не искаш да се показват и 1 на тези, които искаш да са видими",
        },
        required_delivery_pickup_times: {
            default: "Задаване на това, дали полето 'Дата и час' при поръчка да е задължително за избор",
            0: "По подразбиране е избран час 'сега'",
            1: "Задължително трябва да бъде избран час"
        },
        sms_phone_after_order: {
            default: "Телефонен номер, на който се изпраща смс при нова поръчка от тип Доставка/Взимане от място/От стая",
            visible_description: "Телефонния номер трябва да започва с кода, напр: 359..."
        },
        sms_phone_after_spot_order: {
            default: "Телефонен номер, на който се изпраща смс при нова поръчка от тип От обекта",
            visible_description: "Телефонния номер трябва да започва с кода, напр: 359..."
        },
        new_order_email_address: {
            default: "Имейл адрес, на който се получават мейли при нова поръчка от тип Доставка/Взимане от място",
        },
        new_room_order_email_address: {
            default: "Имейл адрес, на който се получават мейли при нова поръчка от тип От стая",
        },
        new_spot_order_email_address: {
            default: "Имейл адрес, на който се получават мейли при нова поръчка от тип От обекта",
        },
        show_delivery_price_not_included_text: {
            default: "Показване на текст в количката: 'Цената на доставката не е включена'.",
            0: "Не",
            1: "Да"
        },
        show_cutlery_option_on_making_delivery_order: {
            default: "Показване на опцията за Прибори при поръчка от тип Доставка",
            0: "Не",
            1: "Да"
        },
        show_cutlery_option_on_making_preorder_order: {
            default: "Показване на опцията за Прибори при поръчка от тип Взимане от място",
            0: "Не",
            1: "Да"
        },
        show_cutlery_option_on_making_room_order: {
            default: "Показване на опцията за Прибори при поръчка от Стая",
            0: "Скрита",
            1: "Видима"
        },
        visible_about_page_delivery_prices_text_in_cart: {
            default: "Показване на текста за цената на доставка в количката",
            visible_description: "Ако цената на доставката зависи от теглото на пратката например - отбележете 'Да' и опишете цените на доставка като натиснете на линка встрани или по-долу",
            0: "Не",
            1: "Да"
        },
        clickable_product_variants: {
            default: "Добавяне на икона 'количка' при варианта на продукта",
            0: "Не",
            1: "Да"
        },
        variant_modal_with_comment: {
            default: "Отваряй винаги нов модал (с опция за коментар) при опит за добавяне на продукт в количката",
            0: "Не",
            1: "Да"
        },
        hide_product_images_without_img: {
            default: "Скрий снимката по подразбиране на продукт, който няма снимка",
            0: "Не",
            1: "Да"
        },
        preorder_percent_discount: {
            default: "Отстъпа при поръчка със взимане от място - в проценти /Не се калкулира в цената/"
        },
        ordertype_prices: {
            delivery: "Доставка - цени",
        },
        ordertype_discounts: {
            delivery: "Доставка - отстъпки",
            pickup: "Вземане от място - отстъпки",
            spot: "Поръчка от ресторанта - отстъпки",
        },
        menu_header_background: {
            default: 'Фон на горна лента (Header)'
        },
        menu_header_icons_color: {
            default: 'Цвят на текст на бутоните на горна лента (Header)'
        },
        menu_call_button_background: {
            default: 'Фон на бутон за набиране'
        },
        menu_call_button_color: {
            default: 'Цвят на текст на бутон за набиране'
        },
        menu_top_left_dialpad_button_background: {
            default: 'Фон на бутони в горна лява лента (меню)'
        },
        menu_top_left_dialpad_button_color: {
            default: 'Цвят на текст на бутони в горна лява лента (меню)'
        },
        menu_product_addtocart_button_background: {
            default: 'Фон на бутон за добавяне в количката - Страница списък с продукти'
        },
        menu_product_addtocart_button_color: {
            default: 'Цвят на текст на бутон за добавяне в количката - Страница списък с продукти'
        },
        menu_product_viewpage_addtocart_button_background: {
            default: 'Фон на бутон за добавяне в количката - Страница преглед на продукт'
        },
        menu_product_viewpage_addtocart_button_color: {
            default: 'Цвят на текст на бутон за добавяне в количката - Страница преглед на продукт'
        },
        menu_dialogs_background: {
            default: 'Фон на Модал/Диалогови прозорци'
        },
        menu_dialogs_colors: {
            default: 'Цвят на текста на Модал/Диалогови прозорци'
        },
        menu_underline_colors: {
            default: 'Фон на лентата на подчертаване (Под категориите и под бутоните в "менюто" (горе вляво)'
        },
        bigger_logo: {
            default: 'По-голямо лого /Цвета не е от значение към момента/'
        },
        product_theme: {
            default: 'Тема на продуктите',
            0: "Тема 1 - Бар /Модерна/",
            1: "Тема 2 - Бар /Опростена/",
            2: "Тема 3 - Ресторант /Модерна/",
            3: "Тема 4 - Ресторант /Опростена/",
            4: "Тема 5 - Хотел /Модерна/",
            5: "Тема 7 - Хотел /Опростена/",
            6: "Тема 8 - Хотел /НОВА/"
        },
        admin_notify_on_new_order: {
            default: 'Звукова нотификация при нова поръчка',
            1: 'Маркиране на поръчката като прочетена при натискане върху бутона "Нови"',
            2: 'Маркиране на поръчката като прочетена при смяна на статуса ѝ'
        },
        allow_auto_print_orders: {
            default: "Автоматично разпечатване на нови поръчки",
            0: 'Не',
            1: 'Да',
        },
        SMS_CLIENT_API_KEY: {
            default: "API KEY FOR CLICKATELL",
            0: 'q8ppKOM_Sy-gydxpYqKXvw== /Ga - 55, Bulg - 54/',
            1: 'OXCQWa2cRrGHi0JKi_-SWA== /Re - 19, Fo - 21, Flo - 47/',
            2: 'BhOHhBm2RkG7FPmfeaHcrA== /SYSTEM PHONES/'
        },
        delivery_radiuses: "Доставка - радиуси",
        delivery_shapes: "Доставка - карти"
    },
    settings_categories: {
        Languages: "Език и локализация",
        Products: "Продукти",
        Images: "Снимки",
        CategoriesAndSubcategories: "Категории и подкатегории",
        Orders: "Поръчки",
        General: "Общи",
        OrdersDelivery: "Поръчки - Доставка",
        OrdersPreorder: "Поръчки - Взимане от място",
        Layout: "Изглед",
        Others: "Други",
        Promotions: "Промоции",
        Hotel: "Хотел",
        Banners: "Банери",
        Colors: "Цветове",
        Themes: "Теми",
        OrdersPrint: "Принтер за поръчки",
        AdminSettings: "Администрация",
        current_warehouse_system: "Складова система",
        current_delivery_system: "Система за доставки",
        current_payment_provider: "Онлайн плащания",
    },
    users: {
        add: "Добави потребител",
        edit: "Промяна на потребител",
        email: "Имейл",
        password: "Парола",
        date_of_registration: "Дата на регистрация",
        registered_users: "Регистрирани потребители",
        fullname: "Имена",
        address: "Адрес",
        phone: "Телефон",
    },
    dashboard: {
        orders: "Поръчки",
        online_customers: "Онлайн клиенти",
        monthly_turnover: "Месечен оборот",
        happy_customers: "Доволни клиенти",
        totalAndTodayOrders: "Общо поръчки {total}, поръчки днес: {today}",
    },
    statistics: {
        users_by_date_count: "Уникални потребители по дата",
        page: "Страница",
        avg_time_spent: "Средно време",
        avg_time_spent_per_page: "Средно време прекарано на всяка страница",
        total_unique_users: "Брой уникални потребители",
        orders_count: "Брой поръчки",
        total_amount: "Сума",
        avg_amount: "Средно",
        turnover_by_date: "Оборот по дата",
        orders_count_by_date: "Брой поръчки по дата",
        most_ordered_product_variants: "Най-поръчвани продукти-варианти",
        times_ordered: "Пъти поръчано",
        product_name: "Продукт",
        top_visited_pages: "Топ посещавани страници и време прекарано на тях"
    },
    roles: {
        SUPER_ADMIN: "Супер Админ",
        CLIENT_ADMIN: "Админ на всички обекти",
        SALES_ADMIN: "Мениджър продажби",
        PLACE_ADMIN: "Админ на обект",
        WAITER: "Сервитьор",
        PDF_MENU_ADMIN: "Админ на снимково меню",
        RASPBERRY_ADMIN: 'RPB', //"Raspberry",
        USER: "Обикновен потребител"
    },
    clients: {
        title: "Клиенти",
        add: "Добави клиент",
        edit: "Промяна на клиент",
        delete: {
            title: "Изтрий клиент",
            confirm: "Сигурен ли си, че искаш да изтриеш клиента "
        },
        contract_start_date: "Договор: начална дата",
        trial_start_date: "Тестов период: начало",
        trial_end_date: "Тестов период: край",
        monthly_fee: "Месечна такса",
        address: "Адрес",
        additional_info: "Допълнителна информация (договорки)",
        owner_name: "Име на собственик",
    },
    regions: {
        title: "Региони",
        add: "Добави регион",
        edit: "Промяна на регион",
        delete: {
            title: "Изтрий регион",
            confirm: "Сигурен ли си, че искаш да изтриеш регион "
        },
    },
    login: {
        title: "Влез в профила си",
        email: "Имейл",
        password: "Парола",
        login: "Влез"
    },
    feedback: {
        questions: {
            title: "Обратна връзка - въпроси",
            add: "Добави въпрос",
            edit: "Промяна на въпрос",
            question: "Въпрос",
            question_type: "Тип",
            question_types: {
                'stars': "Въпрос със звезди",
                'text': "Въпрос с писмен отговор"
            }
        },
        answers: {
            title: "Обратна връзка - отговори",
        }
    },
    invoices: {
        year: "Година",
        month: "Месец",
        title: "Фактури",
        company_name: "Име на фирма",
        company_eik: "ЕИК",
        company_vat_registered: "Регистрирана по ДДС",
        company_city: "Град на фирма",
        company_address: "Адрес на фирма",
        company_person: "МОЛ",
        company_email: "Имейл",
        download: 'Изтегли',
        price: "Цена",
        price_with_words: "Цена с думи",
        date: "Дата",
        service_name: "Услуга",
        service_price: "Цена на услуга",
        number: "Номер фактура",
        add: "Нова фактура",
        invoice_original: "Оригинал",
        invoice_copy: "Копие",
        status: "Статус",
        new: 'Нова',
        sent: 'Изпратена',
        paid: 'Платена',
        canceled: 'Анулирана',
        remind_on: 'Напомни на',
        sort_by_date: 'Сортирай по дата',
        month: "Месец",
        place: "Обект",
        partly_payment: 'Частично плащане'
    },
    proforms: {
        title: "Проформи",
        company_name: "Име на фирма",
        company_eik: "ЕИК",
        company_vat_registered: "Регистрирана по ДДС",
        company_city: "Град на фирма",
        company_address: "Адрес на фирма",
        company_person: "МОЛ",
        company_email: "Имейл",
        download: 'Изтегли',
        price: "Цена",
        price_with_words: "Цена с думи",
        date: "Дата",
        service_name: "Услуга",
        service_price: "Цена на услуга",
        number: "Номер Проформа Фактура",
        add: "Нова Проформа",
        invoice_original: "Проформа Фактура",
        invoice_copy: "Проформа Фактура",
        status: "Статус",
        new: 'Нова',
        sent: 'Изпратена',
        paid: 'Платена',
        remind_on: 'Напомни на',
        sort_by_date: 'Сортирай по дата',
        partly_payment: 'Частично плащане'
    },
    bookings: {
        title: "Bookings",
        duration: "Продължителност",
        service: "Услуга",
        statuses: {
            unconfirmed: "Непотвърдена",
            confirmed: "Потвърдена",
            canceled: "Анулирана",
        },
        start_time: "От",
        end_time: "До",
        rooms: "Стаи",
        add_room: "Добави стая",
        edit_room: "Промени стая",
        all: "Всички",
        new: "Непотвърдени",
        confirmed: "Потвърдени",
        calceled: "Анулирани",
    },
    table_groups: {
        title: "Групи",
        min_people_count: "Мин. брой хора за резервация на групата",
        max_people_count: "Макс. брой хора за резервация на групата",
        tables: 'Маси в групата'
    },
    table_regions: {
        title: "Региони",
        tables: 'Маси в региона',
        add: "Добави регион",
        edit: "Редактиране на регион"
    },
    restaurant_halls: {
        title: "Зали",
        tables: 'Маси в залата',
        add: "Добави зала",
        edit: "Редактиране на зала"
    },
    reservations: {
        title: "Резервации",
        statuses: {
            unconfirmed: "Непотвърдена",
            confirmed: "Потвърдена",
            canceled: "Анулирана",
        },
        without_table: "Без Маса",
        start_time: "От",
        end_time: "До",
        all: "Всички",
        new: "Непотвърдени",
        confirmed: "Потвърдени",
        canceled: "Анулирани",
        date: "Дата и час",
        people_count: "Бр. хора",
        restaurant_hall: "Зала",
        tables: "Маси",
        people: "човека",
    },
    ordertypes: {
        delivery: "Доставка",
        preorder: "Взимане от място",
        spot: "На място",
        spot_browse: "Само за разглеждане",
        tablet_mode: "Таблет режим",
        room: "От стая",
    },
    promocode: {
        ignore_other_discounts: 'Игнорирай другите отстъпки',
        never_become_used: 'Вечен (Може да бъде използван многократно - не гледа дали е използван)',
        never_become_used_short: 'Многократен'
    },
    general_promotions: {
        title: "Промоции",
        edit: "Промяна на промоция",
        add: "Създаване на промоция",
        name: "Име",
        link: "Линк",
        content: 'Съдържание',
        active_from: "Активна от",
        active_to: "Активна до",
    },
    registered_user_discount: {
        title: "Отстъпки за регистрирани потребители",
        description: "При активиране, регистрираните клиенти получават отстъпка на всяка X поред поръчка. Пример: на всяка 5-та поръчка, потребителят ще получи 5лв отстъпка от сметката",
        how_it_works: "Как работи?"
    },
    delivery_radiuses: {
        radius_start: "Радиус начало (м.)",
        radius_end: "Радиус край (м.)",
        min_order_price: "Мин. цена на поръчка за радиус",
        delivery_price: "Цена на доставка за радиус",
        required_order_price_for_free_delivery: "Цена на поръчка за безплатна доставка (празна за да не се прилага)",
        import_shape_map: "Импортиране на координатна карта",
        import_shape_map_info: "Поставете координати (lng, lat) разделени с нов ред в следния формат xx.xxxxx, yy.yyyyy...  Цената на доставката се определя от радиусите по-долу в зависимост от дистанцията от обекта.",
        shape_map_imported_text: "Карта е импортирана и се използва. Ако желаете да импортирате нова, поставете координатните 2ки долу в полето",
        delete_shape_map: "Изтриване на карта",
        shape_map_not_imported_text: "Няма импортирана карта",
    },
    delivery_shapes: {
        title: "Доставки - Карти",
        add: "Добави карта",
        edit: "Промяна на карта",
        min_order_price: "Мин. цена на поръчка",
        delivery_price: "Цена на доставка",
        required_order_price_for_free_delivery: "Цена на поръчка за безплатна доставка (празна за да не се прилага)",
        coords: "Координати (lat, lng)",
        priority_info: "Ако има 2 застъпващи се карти (1 адрес попада и в 2-те), ще се вземат данните на тази, която е с по-нисък приоритет",
        check_if_address_in_polygon: "Въведи адрес за проверка дали е в региона за доставка",
        check_address: "Провери адрес",
        map: "Карта - оформете региона на доставка според нуждите ви",

    },
    place_images: {
        title: "Галерия",
        add: "Добави картинка"
    },
    warehouse_systems: {
        warehouse_system: "Складова система",
        sync_categories: "Синхронизирай категории",
        sync_products: "Синхронизирай продукти",
        sync_order_statuses: "Синхронизирай статуси на поръчка",
        sync_order_delivery_channels: "Синхронизирай канали за доставка",
        sync_order_payment_types: "Синхронизирай платежни методи",
    },
    delivery_systems: {
        delivery_system: "Система доставки",
        sync_cities_and_regions: "Синхронизирай градове и региони за доставка",
        resend_order: "Изпрати пак"
    },
    payment_providers: {
        payment_provider: "Доставчик на плащания",
        choose_payment_provider: "Избери доставчик на плащания",
    }
}