import React, { Component } from 'react'
import socketIOClient from "socket.io-client";
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED, FILTER_TABLE_NUMS_NAME, API_DOMAIN, SOCKET_URL, FILTER_PLACES_NAME, FILTER_TABLE_REGIONS_NAME, PRODUCT_PRINT_GROUPS_NAME } from '../../constants.js'
import {
    listOrders, getUserNeeds, updateOrderStatus, updateProductVariantStatus, removeOrder, removeBulkOrdersForToday, updateUserNeedsStatusById, updateOrderByTableId, updateOrderPrinted,
    updateOrderPrintedByPlace, updateSeen, updateOrderById, updateAnonUser, resendOrderToDeliverySystem
} from '../../actions/ordersActions';
import { listProducts } from '../../actions/productsActions';
import { listIngredients } from '../ingredients/actions/ingredients.actions';
import { showLoading, hideLoading } from '../common/loading/loading.actions';
import { addErrorMessages, clearMessages, addSuccessMessage } from '../messages/messages.actions';
import { listTableRegions, listtableRegionsForSelectedPlaces } from './../table_regions/actions/tableRegions.actions';
import { listTablesForOrders } from './../../actions/tablesActions';
import { listProductPrintGroups } from '../productPrintGroup/actions/productPrintGroup.actions.js'

import { setSoundActivation, changeOrdersStyleType, openConfirmModal } from '../../actions/adminActions'
import ListEachOrder from './ListEachOrder';
import MessagesContainer from './../messages/MessagesContainer';
import Pagination from "react-js-pagination";
import Filter from './Filter';
import Utils from './../../utils/Utils';
import ListEachUserNeeds from './ListEachUserNeeds'
import ListEachUserNeedsBox from './ListEachUserNeedsBox'
import ListEachBillWanted from './ListEachBillWanted'
import ListEachBillWantedBox from './ListEachBillWantedBox'
import Sound from 'react-sound';
import moment from 'moment';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';
import ComponentToPrintDuplicatedOrder from './ComponentToPrintDuplicatedOrder';
import Auth from '../../utils/Auth.js';
import enum_admin_notify_on_new_order from '../common/enums/admin_notify_on_new_order.json';
import ListEachOrderBox from './ListEachOrderBox'
import Cookies from 'universal-cookie';
import soundfileBillWanted from './sounds/bill-wanted/notification_sound_bill_wanted.mp3'
import soundfileUserNeeds from './sounds/user-needs/notification_sound_user_need.mp3'
import soundfileNoInternet from './sounds/no-internet/notification_sound_no_internet.mp3'
import DeleteOrderModal from './DeleteOrderModal';
import SoundActivatedDialog from './../common/SoundActivatedDialog';
import { WonderPush } from 'react-wonderpush';
import { editSetting } from './../../components/settings/actions/settings.actions';
import WarehouseErrorsModal from './modals/WarehouseErrorsModal.js';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const cookies = new Cookies();
// var socket = socketIOClient(SOCKET_URL,
//     {
//         transports: ['websocket'],
//         secure: true,
//         reconnect: true,
//         rejectUnauthorized: false,
//         /*transportOptions: {
//             polling: {
//                 extraHeaders: {
//                     'x-auth-token': Auth.getToken(),
//                 },
//             },
//         },*/
//     });
// if (socket && Auth.getUserPlace()) socket.emit('join', "place_" + Auth.getUserPlace()); // Joining room with place id 
const audioNoInteret = new Audio(soundfileNoInternet)

class ListOrdersPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search_id: '',
            search_status: '',
            search_payment_type: '',
            search_type: '',
            search_table_num: '',
            search_client_name: '',
            search_client_phone: '',
            search_client_address: '',
            search_date_from: moment().subtract(0, 'days').toDate(),
            search_date_to: '',
            search_table_regions: [],
            timer: '',
            timerNoInternet: '',

            activeTab: 1,

            getDeletedOrders: 0,
            getCompletedUserNeeds: 0,
            lastAliveSocket: 0,
            socketFirstTimeFetched: 0,

            // Last read
            lastReadOrdered: 0,

            printingState: false,
            preSelectedFilterTables: [],
            preSelectedFilterTableRegions: [],

            visibleFilter: false,
            printedOrders: [],
            printedDuplicateOrders: {},
            printedDuplicateOrdersClientMark: {},

            orderSound: null,
            notificationTimeout: false,

            deleteOrderModalOpened: false,
            deleteOrderId: '',
            deleteBulkOrdersForToday: false,
            deletePlaceOrderNumber: '',

            allItems: [],
            useSocketOrders: 0,

            useSocketUserNeeds: 0,
            allUserNeeds: 0,

            hasInternet: true,
            filterPlaces: [parseInt(Auth.getUserPlace())],
            prodPrintGroups: [],
            hasNewOrders: false,
            warehouseErrorsModalOpened: false,
            warehouseErrorsModalData: null,
            orderIdsToPrint: []
        }

        this.orderRefs = []; //React.createRef([])
        this.orderRefsDuplicatedOrders = []; //React.createRef([])
        this.orderRefsDuplicatedOrdersClientMark = []; //React.createRef([])

    }

    setOrderIdsToPrint = (arr) => {
        this.setState({ orderIdsToPrint: arr })
    }

    handlePrintAllOrders = (ordersArray) => {
        // console.log('orderItems',ordersArray);
        const orderIds = ordersArray.map(order => order.id);

        // this.setOrderIdsToPrint(ordersArray); // Pass order IDs array to the child component
        this.setOrderIdsToPrint(orderIds); // Pass order IDs array to the child component
    };

    callAndroidFunc = () => {
        // console.log("ANDROID")
        if (window && window.Android) {
            // console.log("HAS ANDROID")
            // console.log(window.Android)

            window.Android.wakeUpScreen();
        }
    }

    componentWillMount() {
        this.props.listTableRegions();
        this.props.listtableRegionsForSelectedPlaces();
        this.props.listTablesForOrders();
        this.props.listProductPrintGroups();

        if (localStorage.getItem('tabl_raspberry') != 1 && window.location.href.split('?') && window.location.href.split('?')[1] && window.location.href.split('?')[1].indexOf('tabl_raspberry=1') != -1) {
            localStorage.setItem('tabl_raspberry', 1);
            window.location.reload();
        }

        if (localStorage.getItem('tabl_printer') != 1 && window.location.href.split('?') && window.location.href.split('?')[1] && window.location.href.split('?')[1].indexOf('tabl_printer=1') != -1) {
            localStorage.setItem('tabl_printer', 1);
            window.location.reload();
        }

    }

    makeFilter = () => {
        this.props.listtableRegionsForSelectedPlaces();
        this.props.listTablesForOrders();
    }

    componentWillReceiveProps(nextProps) {
        const { settings, translations } = this.props;
        // tablesForOrders = tables for places in
        // Preselect search table nums 
        if (Object.keys(nextProps.tablesForOrders).length > 0) {
            let preSelectedFilterTables = [];
            let tables = window.localStorage.getItem(FILTER_TABLE_NUMS_NAME) ? JSON.parse(window.localStorage.getItem(FILTER_TABLE_NUMS_NAME)) : [];
            tables.map(num => {
                let thisTable = Object.values(nextProps.tablesForOrders).filter(t => t.table_num == num);
                let id = thisTable[0] ? thisTable[0].id : null;
                preSelectedFilterTables.push({ table_num: num, id: id });
            })
            this.setState({ search_table_num: tables, preSelectedFilterTables: preSelectedFilterTables });
        }

        if (Object.keys(nextProps.tableRegionsForSelectedPlaces).length > 0) {
            let preSelectedFilterTableRegions = [];
            let regions = window.localStorage.getItem(FILTER_TABLE_REGIONS_NAME) ? JSON.parse(window.localStorage.getItem(FILTER_TABLE_REGIONS_NAME)) : [];
            Object.values(regions).map(regId => {
                let thisRegion = Object.values(nextProps.tableRegionsForSelectedPlaces).find(t => t.id == regId);
                if (thisRegion) {
                    preSelectedFilterTableRegions.push({ name: thisRegion.name + ' - ' + this.props.places.find(p => p.id == thisRegion.placeId).name, id: thisRegion.id });
                }
            })
            this.setState({ search_table_regions: regions, preSelectedFilterTableRegions: preSelectedFilterTableRegions });
        }

        const sound_notification_new_order = Utils.getSettingsValue(settings, "sound_notification_new_order");
        if (sound_notification_new_order && !this.state.orderSound) {
            import(`./sounds/new-order/${sound_notification_new_order}.mp3`).then(e => this.setState({ orderSound: e })).catch(err => this.props.addErrorMessages([translations && translations.messages && translations.messages.errors && translations.messages.errors.sound_not_found ? translations.messages.errors.sound_not_found : 'Sound not found']))
        }

        if (Object.keys(nextProps.places).length > 0) {
            let filterPlaces = window.localStorage.getItem(FILTER_PLACES_NAME) ? JSON.parse(window.localStorage.getItem(FILTER_PLACES_NAME)) : [];
            this.setState({ filterPlaces: filterPlaces });
        }
        if (Object.keys(nextProps.productPrintGroups).length > 0) {
            let prodPrintGroups = window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME) ? JSON.parse(window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME)) : [];
            this.setState({ prodPrintGroups: prodPrintGroups });
        }

        // console.log("NEXT ", nextProps);
        let hasNewOrders = false;
        if (nextProps.orders) {
            for (let i = 0; i < nextProps.orders.length; i++) {
                if (nextProps.orders[i].seen_order == false) {
                    hasNewOrders = true;
                    break;
                }
            }
            if (hasNewOrders) {
                this.callAndroidFunc();
            }
        }


    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/list-orders/' + this.props.ordertype + '?page=' + page))
    }

    updateOrderByTableId = params => {
        this.props.updateOrderByTableId(params)
        this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to)
    }

    updateStatus = (orderId, status, order) => { // Socket updated
        // this.props.updateOrderStatus(orderId, { status: status }, order);
        this.props.showLoading()
        const { settings } = this.props;
        this.props.updateOrderStatus(orderId, { status: status }, order)
            .then(post => {
                this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to)
            }).catch(error => {
                this.props.addErrorMessages(error);
            });

        if (Utils.getSettingsValue(settings, 'admin_notify_on_new_order', '1') == enum_admin_notify_on_new_order.ORDERED_ORDERS) {
            if (order.ordertypeId == 1 && ((order.seen_order == 0 && status != "paid" && status != "ordered") || (order.seen_bill_wanted == 0 && status == "paid"))) {
                this.updateSeen([order.tableId], status == "paid" ? 'seen_bill_wanted' : 'seen_order')
                this.props.hideLoading();
            } else {
                this.props.updateOrderById(orderId, { seen_order: 1 })
                this.props.hideLoading();
            }
        }
        this.props.hideLoading();
    }

    updatePrinted = (orderId, groupPrinted) => { // Socket updated
        const now = new Date();
        const printed_on = new Date().getFullYear() + '-' + parseInt(new Date().getMonth()) + 1 + '-' + new Date().getDate() + ' ' + new Date().getHours() + ':' + new Date().getMinutes()
        if (groupPrinted) {
            this.props.updateOrderPrinted(orderId, { printedGroups: JSON.stringify(groupPrinted) });
            // return; // It must return - Do not mark as printed the whole order
        } else {
            this.props.updateOrderPrinted(orderId, { printed: 1, printed_on: printed_on });
        }
    }

    updatePrintedTimes = (orderId, printedTimes, settings_order_print_duplicate_order) => { // Socket updated
        this.props.updateOrderPrinted(orderId, { printed_times: ((parseInt(printedTimes) || 0) + 1), duplicate_printing_done: (((parseInt(printedTimes) || 0) + 1) == parseInt(settings_order_print_duplicate_order)) ? 1 : 0 });
    }

    updatePrintedTimesClientMark = (orderId, printedTimesMarkForClient, settings_order_print_duplicate_order_mark_for_client) => { // Socket updated
        const printed_on = new Date().getFullYear() + '-' + parseInt(new Date().getMonth()) + 1 + '-' + new Date().getDate() + ' ' + new Date().getHours() + ':' + new Date().getMinutes()
        this.props.updateOrderPrinted(orderId, { printed: 1, printed_on: printed_on, printed_times_mark_for_client: ((parseInt(printedTimesMarkForClient) || 0) + 1), duplicate_printing_done_mark_for_client: (((parseInt(printedTimesMarkForClient) || 0) + 1) == parseInt(settings_order_print_duplicate_order_mark_for_client)) ? 1 : 0 });
    }

    updatePrintedByPlace = () => { // Socket updated
        this.props.updateOrderPrintedByPlace(cookies.get('tabl_a_place'), { printed: 1, duplicate_printing_done: 1, duplicate_printing_done_mark_for_client: 1 });
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.handlePageChange(1);
    }

    onFieldChange = (fieldName, val) => {
        if ((fieldName == 'search_date_from' || fieldName == 'search_date_to') && isNaN(val)) {
            return
        }

        this.setState({ [fieldName]: val });

        if (fieldName == 'search_date_from') {
            this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, val, this.state.search_date_to)
        }
        if (fieldName == 'search_date_to') {
            this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, val)
        }
    }

    updateVariantStatus = (productVariantId, status) => { // Socket updated
        this.props.updateProductVariantStatus(productVariantId, { status: status, placeId: cookies.get('tabl_a_place') })
            .then(post => {
                this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to)
            })
            .catch(error => {
                this.props.addErrorMessages(error);
            });
    }

    openDeleteOrderModal = (orderId, placeOrderNumber) => {
        this.setState({ deleteOrderModalOpened: true, deleteOrderId: orderId, deletePlaceOrderNumber: placeOrderNumber })
    }

    openBulkDeleteOrderModal = () => {
        this.setState({ deleteOrderModalOpened: true, deleteBulkOrdersForToday: true, deletePlaceOrderNumber: 'всички за деня / all for the day' })
    }


    openRejectOrderModal = (order) => {
        this.props.openConfirmModal("Сигурни ли сте, че искате да откажете тази поръчка ? / Are you sure you want to cancel this order ?",
            () => this.updateStatus(order.id, "rejected", order),
            () => void (0)
        )
    }

    openWarehouseErrorsModal = (data) => {
        this.setState({ warehouseErrorsModalOpened: true, warehouseErrorsModalData: data })
    }

    deleteOrder = () => { // Socket updated
        if (this.state.deleteBulkOrdersForToday) {
            this.props.removeBulkOrdersForToday(Auth.getUserPlace())
                .then(resp => {
                    this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to)
                })
                .catch(error => {
                    this.props.addErrorMessages(error);
                });
        } else {
            this.props.removeOrder(this.state.deleteOrderId)
                .then(resp => {
                    this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to)
                })
                .catch(error => {
                    this.props.addErrorMessages(error);
                });
        }

    }

    filterFunc = (i) => {
        return (
            (this.state.search_id.length > 0 ? i.place_order_number == this.state.search_id : true)
            && (this.state.search_status.length > 0 ? i.status == this.state.search_status : true)
            && (this.state.search_payment_type.length > 0 ? i.payment_method == this.state.search_payment_type : true)
            && (this.state.search_type.length > 0 ? i.ordertypeId == this.state.search_type : true)
            && (i.table && i.table.table_num && this.state.search_table_num && this.state.search_table_num.length > 0 && Object.values(this.state.search_table_num).length > 0 ? this.state.search_table_num.includes(i.table.table_num.toLocaleLowerCase()) : true)
            && (this.state.search_table_regions.length > 0 && Object.values(this.state.search_table_regions).length > 0 ? (i.table && i.table.tableRegionId ? this.state.search_table_regions.includes(i.table.tableRegionId) : false) : true)
            && (i.client_name || '').toLocaleLowerCase().includes((this.state.search_client_name).toLocaleLowerCase())
            && (i.client_phone || '').toLocaleLowerCase().includes((this.state.search_client_phone).toLocaleLowerCase())
            && (i.client_address || '').toLocaleLowerCase().includes((this.state.search_client_address).toLocaleLowerCase())
            // && (this.state.search_date.length > 0 ? this.state.search_date == moment(i.createdAt).format("DD.MM.YYYY") : true)
        )
    }

    updateSeen = (tableIds, method = 'seen_order', unreadOrderedItems) => { // Socket updated
        const { tables } = this.props;
        let currPlace = this.props.places.find(p => p.id == Auth.getUserPlace());
        const currPlaceOrderTypes = currPlace ? currPlace.ordertypes : []

        if (!tableIds && tables) {
            tableIds = []
            const filteredTables = window.localStorage.getItem(FILTER_TABLE_NUMS_NAME) ? JSON.parse(window.localStorage.getItem(FILTER_TABLE_NUMS_NAME)) : [];

            if (filteredTables) {
                Object.values(tables).map(t => filteredTables.includes(t.table_num) ? tableIds.push(t.id) : void (0));
            }
        }

        if (unreadOrderedItems != 0) {
            let placesIds = window.localStorage.getItem(FILTER_PLACES_NAME) && JSON.parse(window.localStorage.getItem(FILTER_PLACES_NAME)) || [];
            this.props.updateSeen(tableIds, method, placesIds)
                .then((post) => {
                    this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to)

                    if ((currPlaceOrderTypes.findIndex(p => p.type == "spot") > -1) || (currPlaceOrderTypes.findIndex(p => p.type == "room") > -1)) {
                        this.props.getUserNeeds(this.state.filterPlaces, this.state.getCompletedUserNeeds, 0);
                    }
                    // dispatch(getUserNeeds(placesIds)); //removed because of sockets
                    // dispatch(hideLoading());
                })
                .catch(function (error) {
                    this.props.addErrorMessages([`Cannot mark as read table ids [${tableIds}]. Refresh this page or contact us`]);
                    // dispatch(hideLoading());
                });
        }
    }

    getSelectedTableIds = () => {
        const filteredTables = window.localStorage.getItem(FILTER_TABLE_NUMS_NAME) ? JSON.parse(window.localStorage.getItem(FILTER_TABLE_NUMS_NAME)) : [];
        if (filteredTables.length == 0) return [];

        let filteredTableIds = [];
        if (this.props.tables) {
            filteredTables.map(t => Object.values(this.props.tables).map(tt => t == tt.table_num ? filteredTableIds.push(tt.id) : void (0)))
        }
        return filteredTableIds;
    }

    getActiveStatuses = (orderType = 'spot') => {
        if (orderType == 1) orderType = 'spot';
        if (orderType == 2) orderType = 'delivery';
        if (orderType == 3) orderType = 'preorder';
        if (orderType == 6) orderType = 'room';
        if (orderType == 7) orderType = 'served_on_spot';

        var statuses = this.props.orderActiveStatuses[orderType] || {};
        var activeStatuses = {};

        if (orderType == 'all') {
            var statusesArr = [];
            Object.values(this.props.orderActiveStatuses).map(s => {
                statusesArr.push(s)
            })
            statuses = (statusesArr.reduce(function (result, current) {
                return Object.assign(result, current);
            }, {}));
        }

        Object.keys(statuses).map(s => {
            if (statuses[s] === 1) {
                // activeStatuses[s] = this.props.translations.orders.statuses[s];
                activeStatuses[s] = Utils.getSettingsValue(this.props.settings, 'admin_statuses_translation_names')[s]; //Getting admin statuses from settings

            }
        })

        return activeStatuses;
    }


    checkTableIdInFilteredTables = (tableId) => {
        const filteredTables = window.localStorage.getItem(FILTER_TABLE_NUMS_NAME) ? JSON.parse(window.localStorage.getItem(FILTER_TABLE_NUMS_NAME)) : [];

        if (filteredTables.length == 0) return true;

        // console.log('filteredTables! ', filteredTables);

        let filteredTableIds = [];
        if (this.props.tablesForOrders) {
            filteredTables.map(t => Object.values(this.props.tablesForOrders).map(tt => t == tt.table_num.toLocaleLowerCase() ? filteredTableIds.push(tt.id) : void (0)))
        }
        // console.log('filteredTableIds', filteredTableIds)
        // console.log('tableId', tableId)
        return filteredTableIds.includes(tableId);
    }

    onTableSelect = (selectedList, selectedItem) => {
        let currSearchedTables = this.state.search_table_num;
        currSearchedTables.push((selectedItem.table_num).toLocaleLowerCase());
        this.setState({ search_table_num: currSearchedTables })
        window.localStorage.setItem(FILTER_TABLE_NUMS_NAME, JSON.stringify(currSearchedTables));
    }

    onTableRemove = (selectedList, removedItem) => {
        let currSearchedTables = this.state.search_table_num;
        currSearchedTables = currSearchedTables.filter(e => e !== removedItem.table_num);

        this.setState({ search_table_num: currSearchedTables })
        window.localStorage.setItem(FILTER_TABLE_NUMS_NAME, JSON.stringify(currSearchedTables));
    }

    onTableRegionSelect = (selectedList, selectedItem) => {
        let currSearchedTableRegions = this.state.search_table_regions;
        currSearchedTableRegions.push(selectedItem.id);
        this.setState({ search_table_regions: currSearchedTableRegions })
        window.localStorage.setItem(FILTER_TABLE_REGIONS_NAME, JSON.stringify(currSearchedTableRegions));
    }

    onTableRegionRemove = (selectedList, removedItem) => {
        let currSearchedTableRegions = this.state.search_table_regions;
        currSearchedTableRegions = currSearchedTableRegions.filter(e => e !== removedItem.id);

        this.setState({ search_table_regions: currSearchedTableRegions })
        window.localStorage.setItem(FILTER_TABLE_REGIONS_NAME, JSON.stringify(currSearchedTableRegions));
    }

    onPlaceSelect = (selectedList, selectedItem) => {
        let filterPlaces = this.state.filterPlaces;
        filterPlaces.push(selectedItem.id);
        this.setState({ filterPlaces: filterPlaces })
        window.localStorage.setItem(FILTER_PLACES_NAME, JSON.stringify(filterPlaces));

        this.makeFilter();
        this.props.listOrders(filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to); // Get orders if server was stopped and order is made before the reconection bottom
    }

    onPlaceRemove = (selectedList, removedItem) => {
        let filterPlaces = this.state.filterPlaces;
        filterPlaces = filterPlaces.filter(e => e !== removedItem.id);
        this.setState({ filterPlaces: filterPlaces });
        window.localStorage.setItem(FILTER_PLACES_NAME, JSON.stringify(filterPlaces));

        this.makeFilter();
        this.props.listOrders(filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to); // Get orders if server was stopped and order is made before the reconection bottom
    }

    onProductPrintGroupSelect = (selectedList, selectedItem) => {
        let prodPrintGroups = this.state.prodPrintGroups;
        prodPrintGroups.push(selectedItem.id);
        this.setState({ prodPrintGroups: prodPrintGroups })
        window.localStorage.setItem(PRODUCT_PRINT_GROUPS_NAME, JSON.stringify(prodPrintGroups));

        this.makeFilter();
        this.props.listOrders(prodPrintGroups, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to); // Get orders if server was stopped and order is made before the reconection bottom
    }

    onProductPrintGroupRemove = (selectedList, removedItem) => {
        let prodPrintGroups = this.state.prodPrintGroups;
        prodPrintGroups = prodPrintGroups.filter(e => e !== removedItem.id);
        this.setState({ prodPrintGroups: prodPrintGroups });
        window.localStorage.setItem(PRODUCT_PRINT_GROUPS_NAME, JSON.stringify(prodPrintGroups));

        this.makeFilter();
        this.props.listOrders(prodPrintGroups, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to); // Get orders if server was stopped and order is made before the reconection bottom
    }

    removeLocalStorageKey = (localStorageKey) => {
        localStorage.removeItem(localStorageKey);
        window.location.reload();
    }

    setLocalStorageKey = (localStorageKey, localStorageValue) => {
        localStorage.setItem(localStorageKey, localStorageValue);
        window.location.reload();
    }

    componentDidMount() {
        let currPlace = this.props.places.find(p => p.id == Auth.getUserPlace());
        const currPlaceOrderTypes = currPlace ? currPlace.ordertypes : []

        if (localStorage.getItem('printer')) {
            localStorage.removeItem('printer');
            localStorage.setItem('tabl_printer', 1);
            window.location.reload();
        }

        // Internet connetion check
        const checkForInternetConnection = () => {
            const { translations } = this.props; //This must be here...
            if (!navigator.onLine) {
                audioNoInteret.play();
                if (this.state.hasInternet) {
                    this.setState({ hasInternet: false })
                    this.props.addErrorMessages([translations && translations.common && translations.common.no_internet ? translations.common.no_internet : ''])
                }
            } else {
                if (!this.state.hasInternet) {
                    this.setState({ hasInternet: true })
                    this.props.clearMessages()
                    audioNoInteret.pause();
                    // window.location.reload(); //For sockets
                }
            }
        }
        let timerNoInternet = setInterval(() => checkForInternetConnection(), 3000); //3 seconds
        this.setState({ timerNoInternet });

        this.props.getUserNeeds(this.state.filterPlaces, this.state.getCompletedUserNeeds, 0); //Init get user needs - Socket logic

        // Do not remove it, it is used to rerender each 5 sec for the sound
        // TODO make it without setinterval's queue
        const setNotificationTimeout = () => {

            // // console.log('diff in secs', (new Date() - new Date(this.state.lastAliveSocket)) / 1000)
            // if ((this.state.lastAliveSocket != 0 || this.state.socketFirstTimeFetched == 0) && ((new Date() - new Date(this.state.lastAliveSocket)) / 1000 > 150)) { //if 2.5 min or more inactivity
            //     // console.log('LEAVE AND REJOIN');
            //     // window.location.reload();
            //     // if (this.state.socketFirstTimeFetched) {
            //     // if (!this.state.lastSentRejoinRequest || ((new Date() - new Date(this.state.lastSentRejoinRequest)) / 1000 > 150)) {  //if server is stopped will sent req on 2.5 mins
            // this.props.listProducts();
            // console.log('currPlaceOrderTypes',currPlaceOrderTypes);
            // if ((currPlaceOrderTypes.findIndex(p => p.type == "spot") > -1) || (currPlaceOrderTypes.findIndex(p => p.type == "room") > -1)) {
            this.props.getUserNeeds(this.state.filterPlaces, this.state.getCompletedUserNeeds, 0); //Init get user needs - Socket logic
            // }

            this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to); // Get orders if server was stopped and order is made before the reconection bottom


            //     this.setState({ useSocketOrders: 0 })
            //     //     this.setState({ lastSentRejoinRequest: new Date() })
            //     // }                
            //     socket.emit('leave', "place_" + Auth.getUserPlace()); // Leaving room with place id 
            //     socket.emit('join', "place_" + Auth.getUserPlace()); // Joining room with place id 

            //     let ord = this.state.useSocketOrders ? this.state.allItems : this.props.orders;
            //     if (ord && ord.length > 0 && ord[ord.length - 1] && ord[ord.length - 1].id) {
            //         socket.emit('order_received', { lastOrderId: ord[ord.length - 1].id, placeId: Auth.getUserPlace() }) // Tells the server, that order is received 
            //     }
            // }

            // this.setState({ notificationTimeout: !this.state.notificationTimeout })
        }
        let timer = setInterval(setNotificationTimeout, 15000);
        this.setState({ timer });
        // ---

        if ((currPlaceOrderTypes.findIndex(p => p.type == "spot") > -1) || (currPlaceOrderTypes.findIndex(p => p.type == "room") > -1)) {
            this.props.getUserNeeds(this.state.filterPlaces, this.state.getCompletedUserNeeds, 0); //Init get user needs - Socket logic
        }

        let filteredPlaces = window.localStorage.getItem(FILTER_PLACES_NAME) ? JSON.parse(window.localStorage.getItem(FILTER_PLACES_NAME)) : [];
        this.props.listOrders(filteredPlaces && filteredPlaces.length > 0 ? filteredPlaces : this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to); //Init get orders - Socket logic

        if (!window.localStorage.getItem(FILTER_PLACES_NAME) || window.localStorage.getItem(FILTER_PLACES_NAME) == '[]') {
            window.localStorage.setItem(FILTER_PLACES_NAME, JSON.stringify([Auth.getUserPlace()]))
        }
        // socket.on("last_alive", this.getLastAliveSocket);
        // socket.on("get_all_orders", this.getAllSocketOrders);
        // socket.on("get_all_user_needs", this.getAllSocketUserNeeds);

        // setTimeout(() => {
        //     let o = this.state.useSocketOrders ? this.state.allItems : this.props.orders;
        //     if (o && o.length > 0 && o[o.length - 1] && o[o.length - 1].id) {
        //         socket.emit('order_received', { lastOrderId: o[o.length - 1].id, placeId: Auth.getUserPlace() }) // Tells the server, that order is received 
        //     }
        // }, 60000) // 1 min - If order has come before oppening the browser

    }

    getLastAliveSocket = socketLastAlive => {
        // console.log('socketLastAlive', socketLastAlive)
        this.setState({ lastAliveSocket: socketLastAlive, socketFirstTimeFetched: 1 });
    }

    getAllSocketOrders = socketOrders => {
        // this.setState({ getDeletedOrders: 0, useSocketOrders: 1, allItems: socketOrders });

        // if (socketOrders && socketOrders.length > 0 && socketOrders[socketOrders.length - 1] && socketOrders[socketOrders.length - 1].id) {
        //     socket.emit('order_received', { lastOrderId: socketOrders[socketOrders.length - 1].id, placeId: Auth.getUserPlace() }); // Tells the server, that order is received 
        //     // console.log('order_received', socketOrders[socketOrders.length - 1].id)
        // }
    }

    getAllSocketUserNeeds = socketUserNeeds => {
        this.setState({ useSocketUserNeeds: 1, allUserNeeds: socketUserNeeds });
    }


    componentWillUnmount() {
        clearInterval(this.state.timer);
        clearInterval(this.state.timerNoInternet);
        // socket.emit('leave', "place_" + Auth.getUserPlace()); // Leaving room with place id 
        // socket.off("last_alive", this.getLastAliveSocket);
        // socket.off("get_all_orders", this.getAllSocketOrders);
        // socket.off("get_all_user_needs", this.getAllSocketUserNeeds);
    }


    // initiateSocket = (room) => {
    //     // socket = io(API_DOMAIN);
    //     // console.log(`Connecting socket...`);
    //     if (socket && room) socket.emit('join', room);
    // }


    setSoundActivation(val) {
        this.props.setSoundActivation(val);

        // Used for raspberry with display - Auth
        if (Utils.getSettingsValue(this.props.settings, 'raspberry_used') == 1 && localStorage.getItem('tabl_raspberry') == 1 && Utils.getSettingsValue(this.props.settings, 'raspberry_display') == '1') {
            this.reqFullScreen();
        }

        if (Auth.getDecodedUser().role != 'SUPER_ADMIN' && Auth.getDecodedUser().role != 'SALES_ADMIN') {
            let body = document.body;
            if (body) {
                body.classList.add('brand-minimized');
                body.classList.add('sidebar-minimized');
            }
        }

    }

    reqFullScreen() {
        var docElm = document.documentElement;
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        } else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        }
    }

    changeTimeUntilOrderReady = (e, orderId, order) => {
        let mins = e.target.value;

        if (!isNaN(+mins)) {
            this.props.updateOrderById(orderId, { time_until_order_ready: mins })
        }
    }

    banUnbanAnonUser = (id, banned) => {
        // console.log(this)
        if (banned == 1) {
            this.props.openConfirmModal("Are you sure you want to BAN this user?",
                () => this.props.updateAnonUser({ id: id, banned: banned })
                    .then(resp => {
                        this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to)
                        this.props.addSuccessMessage("Successfully banned device. No more orders can be made on this device")
                    }),
                () => void (0))

        } else {
            this.props.openConfirmModal("Are you sure you want to UNBAN this user?",
                () => this.props.updateAnonUser({ id: id, banned: banned })
                    .then(resp => {
                        this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to)
                        this.props.addSuccessMessage("Successfully unbanned device")
                    }),
                () => void (0))
        }
    }

    // Moved to header.js
    // subscribeToPushNotifications = () => {
    //     window.WonderPush = window.WonderPush || [];
    //     console.log('WonderPush', WonderPush);
    //     window.WonderPush.push(['setUserId', Auth.getDecodedUser().email]);
    //     window.WonderPush.addTag(Auth.getUserPlace());

    //     // window.WonderPush.push(function () {
    //     //     window.WonderPush.addTag(Auth.getUserPlace());
    //     //     console.log('In adding tag');
    //     //     // WonderPush.addTag("economics", "sport", "politics");
    //     // });
    //     // console.log('subscribeToPushNotifications');

    //     // window.WonderPush.push(function () {
    //     //     window.WonderPush.getUserId().then(console.log);
    //     // });


    // }

    editSettings = (setting, newValue) => {
        let post = {
            id: setting.id,
            name: setting.name,
            value: newValue,
            // send_mail: true, //Send notify email
        }

        this.props.editSetting(post);

    }

    setHourBusy = (busy) => {
        this.editSettings(this.props.settings.enabled_busy_hour, busy)
        this.editSettings(this.props.settings.default_time_until_order_ready_delivery, busy == 1 ? Utils.getSettingsValue(this.props.settings, 'busy_hour_time_until_order_ready_delivery') : Utils.getSettingsValue(this.props.settings, 'not_busy_hour_time_until_order_ready_delivery'))
        this.editSettings(this.props.settings.default_time_until_order_ready_pickup, busy == 1 ? Utils.getSettingsValue(this.props.settings, 'busy_hour_time_until_order_ready_pickup') : Utils.getSettingsValue(this.props.settings, 'not_busy_hour_time_until_order_ready_pickup'))
    }

    addMinutesToTime = (timeStr, minutesToAdd) => {
        if (timeStr.indexOf('/') !== -1) {
            timeStr = timeStr.split(' / ')[1]
        }

        if (timeStr.indexOf(':') !== -1) {

            let hours = timeStr.split(':')[0]
            let minutes = timeStr.split(':')[1]

            let date = new Date();
            date.setHours(hours)
            date.setMinutes(parseInt(minutes) + parseInt(minutesToAdd))

            let newHours = date.getHours();
            let newMinutes = date.getMinutes();

            return `${Utils.appendLeadingZeroes(newHours)}:${Utils.appendLeadingZeroes(newMinutes)}`;
        }
    }

    resendOrderToDeliverySystem = (orderId) => {
        this.props.showLoading()

        this.props.resendOrderToDeliverySystem(orderId)
            .then(resp => {
                this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, this.state.search_date_to)

                this.props.hideLoading()
            })
    }

    render() {

        // let prodPrintGroups = window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME) ? JSON.parse(window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME)) : [];
        let prodPrintGroups = window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME) ? JSON.parse(window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME)) : [];

        let userNeeds = this.state.useSocketUserNeeds ? this.state.allUserNeeds : this.props.user_needs;
        let allItems = this.state.useSocketOrders ? this.state.allItems : this.props.orders;

        let currPlace = this.props.places.find(p => p.id == Auth.getUserPlace());
        let currPlaceName = currPlace ? currPlace.name : "-";

        const currPlaceOrderTypes = currPlace ? currPlace.ordertypes : []

        let statuses = this.getActiveStatuses(this.props.ordertype);

        if (this.props.ordertype != 'all') {  // TODO - START FIXING HERE
            allItems = allItems.filter(o => o.ordertypeId == this.props.ordertypeId && this.checkTableIdInFilteredTables(o.tableId))
        }


        let orderItems = allItems.filter(o => this.checkTableIdInFilteredTables(o.tableId));
        // console.log(allItems, orderItems);

        let orderedItems = orderItems.filter(o => (o.status == "ordered") && this.checkTableIdInFilteredTables(o.tableId));
        let cookedItems = orderItems.filter(o => (o.status == "cooked_spot" || o.status == "cooked_delivery" || o.status == "cooked_preorder" || o.status == "cooked_room") && this.checkTableIdInFilteredTables(o.tableId));
        let deliveringServedItems = orderItems.filter(o => (o.status == "served_spot" || o.status == "on_the_way_delivery") && this.checkTableIdInFilteredTables(o.tableId));
        let billWantedItems = orderItems.filter(o => (o.status != "paid" && o.bill_wanted == true) && this.checkTableIdInFilteredTables(o.tableId));
        userNeeds = userNeeds.filter(o => this.checkTableIdInFilteredTables(o.tableId));
        let paidItems = orderItems.filter(o => (o.status == "paid") && this.checkTableIdInFilteredTables(o.tableId));
        let futureItems = orderItems.filter(o => (o.exact_time_selected && !Utils.checkDatesInSameDay(o.delivery_time || o.pickup_time, o.createdAt)) && this.checkTableIdInFilteredTables(o.tableId));


        // Find all tables for the orders for which the bill is wanted
        let billWantedTableIds = []
        billWantedItems.map(b => (!billWantedTableIds.includes(b.tableId)) ? billWantedTableIds.push(b.tableId) : void (0))
        billWantedItems = orderItems.filter(o => billWantedTableIds.includes(o.tableId));

        const setActiveTab = (tabNum, unreadOrderedItems = 123) => {
            this.setState({ activeTab: tabNum });

            if (tabNum == 2) {
                if (orderedItems && orderedItems.length > 0) {
                    if (Utils.getSettingsValue(settings, 'admin_notify_on_new_order', '1') == enum_admin_notify_on_new_order.UNREAD_ORDERS) {
                        this.updateSeen(this.getSelectedTableIds(), 'seen_order', unreadOrderedItems);
                    }
                }
            } else if (tabNum == 6) {
                if (billWantedItems && billWantedItems.length > 0) {
                    if (Utils.getSettingsValue(settings, 'admin_notify_on_new_order', '1') == enum_admin_notify_on_new_order.UNREAD_ORDERS) {
                        this.updateSeen(billWantedTableIds, 'seen_bill_wanted', unreadOrderedItems); //billWantedTableIds
                    }
                }
            } else if (tabNum == 7) {
                if (userNeeds && userNeeds.length > 0) {
                    if (Utils.getSettingsValue(settings, 'admin_notify_on_new_order', '1') == enum_admin_notify_on_new_order.UNREAD_ORDERS) {
                        this.updateSeen(this.getSelectedTableIds(), 'user_needs', unreadOrderedItems); //billWantedTableIds
                    }
                }
            }
        }

        if (this.state.activeTab == 2) {
            orderItems = orderedItems;
        } else if (this.state.activeTab == 3) {
            orderItems = cookedItems;
        } else if (this.state.activeTab == 4) {
            orderItems = deliveringServedItems
        } else if (this.state.activeTab == 5) {
            orderItems = paidItems;
        } else if (this.state.activeTab == 6) {
            orderItems = billWantedItems;
        } else if (this.state.activeTab == 7) {
            orderItems = userNeeds;
        } else if (this.state.activeTab == 8) {
            orderItems = futureItems;
        }

        orderItems = Object.values(orderItems).sort(function (a, b) {
            return a.id - b.id
        })

        if (this.state.search_id.length > 0
            || this.state.search_status.length > 0
            || this.state.search_payment_type.length > 0
            || this.state.search_type.length > 0
            || this.state.search_table_num.length > 0
            || (this.state.search_table_regions.length > 0 && Object.values(this.state.search_table_regions).length > 0)
            || this.state.search_client_name.length > 0
            || this.state.search_client_phone.length > 0
            || this.state.search_client_address.length > 0
            // || this.state.search_date.length > 0
        ) {
            orderItems = Object.values(orderItems).filter(i => this.filterFunc(i));

            allItems = allItems.filter(i => this.filterFunc(i));
            orderedItems = orderedItems.filter(i => this.filterFunc(i));
            cookedItems = cookedItems.filter(i => this.filterFunc(i));
            deliveringServedItems = deliveringServedItems.filter(i => this.filterFunc(i));
            billWantedItems = billWantedItems.filter(i => this.filterFunc(i));
            paidItems = paidItems.filter(i => this.filterFunc(i));
            futureItems = futureItems.filter(i => this.filterFunc(i));
        }
        // console.log("orderItems ", orderItems, this.state.search_table_regions)

        // let unreadBillWantedItems = Object.keys(billWantedTableIds).length;
        let unreadBillWantedItems = billWantedItems.filter(o => o.seen_bill_wanted == 0 && billWantedTableIds.includes(o.tableId) && this.checkTableIdInFilteredTables(o.tableId));

        const pushToBillWanted = (b) => {
            uniqueUnreadBillWantedItems.push(b);
            uniqueUnreadBillWantedItemsIds.push(b.table.id)
        }

        let uniqueUnreadBillWantedItems = [];
        let uniqueUnreadBillWantedItemsIds = [];

        unreadBillWantedItems.map(b => ((b.table && b.table.id) && (!uniqueUnreadBillWantedItemsIds.includes(b.table.id))) ? pushToBillWanted(b) : void (0))
        unreadBillWantedItems = uniqueUnreadBillWantedItems.length;

        let unreadUserNeeds = Object.keys(userNeeds).length;

        let unreadOrderedItems = 0;
        unreadOrderedItems = orderedItems.filter(o => o.seen_order == 0).length;

        let allUnreadUserNeeds = 0;
        unreadUserNeeds = userNeeds.filter(o => {
            if (o.table_userneeds.filter(tn => tn.seen == false).length > 0) {
                allUnreadUserNeeds++;
            }
        });

        const items_count = Object.keys(orderItems).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        const { settings } = this.props;

        if ((unreadOrderedItems > 0) || (unreadBillWantedItems > 0) || (allUnreadUserNeeds > 0)) {
            // navigator.vibrate(350)
        }

        let currSum = 0;
        Object.values(orderItems).map(o => currSum += parseFloat(o.total_price))
        currSum = currSum.toFixed(2);


        const groupedOrderItems = {};

        // Step 1: Grouping by product and category
        orderItems.forEach(order => {
            if (order && order.order_productvariants) {
                order.order_productvariants.forEach(opv => {
                    if (opv.product_variant && opv.product_variant.product) {
                        const productName = opv.product_variant.product.name;
                        const size = opv.product_variant.size;
                        const category = opv.cat_hierarchy && opv.cat_hierarchy.name || '';

                        const key = `${productName} - ${size} - ${category}`;

                        if (groupedOrderItems[key]) {
                            groupedOrderItems[key] += opv.quantity;
                        } else {
                            groupedOrderItems[key] = opv.quantity;
                        }
                    }
                });
            }
        });

        return (
            <main className="main" >
                <div className="loader" style={{
                    position: 'absolute',
                    zIndex: 2,
                    top: '50%',
                    left: '50%',
                    width: 100,
                    height: 100,
                    display: this.state.printingState ? 'block' : 'none'
                }}></div>

                <DeleteOrderModal
                    deleteOrderModalOpened={this.state.deleteOrderModalOpened}
                    deletePlaceOrderNumber={this.state.deletePlaceOrderNumber}
                    deleteOrder={this.deleteOrder}
                    translations={this.props.translations}
                    onClose={() => this.setState({ deleteOrderModalOpened: false, deleteOrderId: '', deletePlaceOrderNumber: '' })}
                />

                <WarehouseErrorsModal
                    warehouseErrorsModalOpened={this.state.warehouseErrorsModalOpened}
                    data={this.state.warehouseErrorsModalData}
                    translations={this.props.translations}
                    onClose={() => this.setState({ warehouseErrorsModalOpened: false })}
                />

                <div className="page-container" style={{ opacity: this.state.printingState ? 0.4 : 1 }}>

                    <SoundActivatedDialog
                        soundActivated={this.props.soundActivated}
                        activateSound={() => this.setSoundActivation(true)}
                        translations={this.props.translations}
                    />

                    {this.props.soundActivated ?
                        <React.Fragment>
                            {unreadOrderedItems > 0 && this.state.orderSound ?
                                <Sound
                                    url={this.state.orderSound && this.state.orderSound.default ? this.state.orderSound.default : ''}
                                    playStatus={Sound.status.PLAYING}
                                />
                                :
                                (unreadBillWantedItems > 0) ?
                                    <Sound
                                        url={soundfileBillWanted}
                                        playStatus={Sound.status.PLAYING}
                                    />
                                    :
                                    (allUnreadUserNeeds > 0) ?
                                        <Sound
                                            url={soundfileUserNeeds}
                                            playStatus={Sound.status.PLAYING}
                                        />
                                        :
                                        // (!this.state.hasInternet) ?
                                        //     < Sound
                                        //         url={soundfileNoInternet}
                                        //         playStatus={Sound.status.PLAYING}
                                        //     />
                                        //     :
                                        null
                            }
                        </React.Fragment>
                        :
                        null
                    }


                    {/* <!-- Breadcrumb--> */}
                    <ol className="breadcrumb"
                        style={{
                            background:
                                (Utils.getSettingsValue(settings, 'allow_auto_print_orders') == 1)
                                    ? (localStorage.getItem('tabl_printer') == 1
                                        ? Utils.getSettingsValue(settings, 'raspberry_used') == 1 ? (localStorage.getItem('tabl_raspberry') == 1 ? '#4dbd74' : '#e10000') : '#4dbd74'
                                        : '#e10000')
                                    : 'white'

                        }}>
                        {/* <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li> */}
                        {/* <li className="breadcrumb-item">Меню</li> */}
                        {/* <li className="breadcrumb-item active">{this.props.translations.sidemenu.orders}</li> */}
                        {/* <!-- Breadcrumb Menu--> */}

                        {this.state.lastAliveSocket ?
                            <div style={{ marginTop: 5 }} title={"Времето на последна връзка със сървъра"}>{new Date(this.state.lastAliveSocket).getDate() + '.' + (parseInt(new Date(this.state.lastAliveSocket).getMonth()) + 1) + '.' + new Date(this.state.lastAliveSocket).getFullYear() + ' ' + new Date(this.state.lastAliveSocket).getHours() + ':' + new Date(this.state.lastAliveSocket).getMinutes() + ':' + new Date(this.state.lastAliveSocket).getSeconds() + ':' + new Date(this.state.lastAliveSocket).getMilliseconds()}</div>
                            :
                            null
                        }

                        <li className="breadcrumb-menu d-md-down-none p0" >
                            <div className="btn-group" role="group" aria-label="Button group" style={{ verticalAlign: 'sub' }}>
                                <>
                                    {(Utils.getSettingsValue(this.props.settings, 'raspberry_used') == '1') ?
                                        localStorage.getItem('tabl_raspberry') == 1 ?
                                            <div>
                                                <span style={{ color: 'white' }}>
                                                    {this.props.translations.orders && this.props.translations.orders.connected_to_mini_comp || ''}</span>
                                                &nbsp;
                                                <button className="btn" style={{ padding: '0 5px' }}
                                                    onClick={() => {
                                                        window.confirm("WARNING: This will remove mini computer connection from current device, are you sure you want to do it ? / ВНИМАНИЕ: Това действие ще преустанови връзката с мини компютъра, сигурни ли сте че искате да продължите ?")
                                                            ? this.removeLocalStorageKey('tabl_raspberry')
                                                            : void (0)
                                                    }}>
                                                    X
                                                </button>
                                            </div>
                                            :
                                            <button className="btn" style={{ padding: 5 }} onClick={() => this.setLocalStorageKey('tabl_raspberry', 1)}>
                                                {this.props.translations.orders && this.props.translations.orders.connect_to_mini_comp || ''}

                                            </button>
                                        :
                                        null
                                    }

                                    {(Utils.getSettingsValue(settings, 'allow_auto_print_orders') == 1) ?
                                        localStorage.getItem('tabl_printer') == 1 ?
                                            <div>

                                                {JSON.parse(window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME)) && JSON.parse(window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME)).length > 0 ?
                                                    <b>
                                                        Печат САМО на избрани групи:&nbsp;
                                                        {JSON.parse(window.localStorage.getItem(PRODUCT_PRINT_GROUPS_NAME)).map(prodGroupid => {
                                                            const selectedProdGroups = this.props.productPrintGroups.find(a => a.id == prodGroupid)
                                                            if (selectedProdGroups && selectedProdGroups.name) {
                                                                return selectedProdGroups.name + ','
                                                            }
                                                        })}
                                                    </b>
                                                    :
                                                    null
                                                }

                                                <span>
                                                    <i class="icon-check icons h5 c-white fsize-15 c-pointer" style={{ padding: '0 5px' }} onClick={() => window.confirm("WARNING: Are you sure you want to mark all orders as printed for current place? / ВНИМАНИЕ: Сигурни ли сте, че искате да маркирате всички поръчки като разпечатани, това означава, че те няма да бъдат отпечатани?") ? this.updatePrintedByPlace() : void (0)} ></i>
                                                </span>
                                                <span
                                                    className=' '
                                                    style={{ color: 'white', padding: '0px !important' }}
                                                    onClick={() => {
                                                        localStorage.setItem('tabl_printer_padding_right',
                                                            Number(window.prompt("Settings: Add printer right padding from 0 to 300. / Настройки: Добавете отстояние вдясно на бележката за принтиране от 0 до 300 | CURRENT VALUE: " + (localStorage.getItem('tabl_printer_padding_right') ? localStorage.getItem('tabl_printer_padding_right') : '0'), "")) || (localStorage.getItem('tabl_printer_padding_right') ? localStorage.getItem('tabl_printer_padding_right') : '0')
                                                        );
                                                        window.location.reload();
                                                    }}
                                                >
                                                    {this.props.translations.orders && this.props.translations.orders.connected_to_printer || ''}
                                                </span>
                                                &nbsp;
                                                <button
                                                    className='btn '
                                                    style={{ padding: '0 5px' }}
                                                    onClick={() => {
                                                        this.props.openConfirmModal("WARNING: This will stop printing from current device, are you sure you want to do it ? / ВНИМАНИЕ: Това действие ще преустанови принтирането от текущото устройство, сигурни ли сте че искате да продължите ?",
                                                            () => this.removeLocalStorageKey('tabl_printer'),
                                                            () => void (0))
                                                    }}>
                                                    X
                                                </button>
                                            </div>
                                            :
                                            <button className="btn" style={{ padding: 5 }} onClick={() => this.setLocalStorageKey('tabl_printer', 1)}>
                                                {this.props.translations.orders && this.props.translations.orders.connect_to_printer || ''}
                                            </button>
                                        :
                                        null
                                    }
                                </>
                            </div>

                            &nbsp;

                            {Auth.hasAccess('PLACE_ADMIN') ?
                                <>
                                    {this.state.activeTab != 7 ?
                                        <div className=" btn-group " role="group" aria-label="Button group" style={{ padding: '0px !important' }}>
                                            <button className="btn label-green btn-success btn-ladda ladda-button" style={{ zIndex: 123 }} onClick={() => {
                                                this.props.listOrders(this.state.filterPlaces, !this.state.getDeletedOrders, 1, this.state.search_date_from, this.state.search_date_to); this.setState({ getDeletedOrders: !this.state.getDeletedOrders, useSocketOrders: 0 });
                                            }}>
                                                <div className="loader" style={{ display: 'inline-block', verticalAlign: 'text-bottom', visibility: this.props.loadingOrders ? 'visible' : 'hidden' }}></div> <span style={{ color: 'white' }}>{this.state.getDeletedOrders ? this.props.translations.orders.hide_deleted : this.props.translations.orders.show_deleted}</span> &nbsp;&nbsp;&nbsp;
                                            </button>
                                            {/* <button class="btn btn-success btn-ladda" data-style="zoom-out" disabled data-loading>Submit</button> */}

                                            {/* <NavLink to={ADMIN_URL+`categories/add`} className="btn" >
                                    <i className="fas fa-plus"></i> &nbsp; Добавяне на категория
                                    </NavLink> 
                                    */}
                                            {/* 
                                    <!--<a className="btn" href="./">
                                        <i className="icon-graph"></i>  Dashboard</a>
                                    <a className="btn" href="#">
                                        <i className="icon-settings"></i>  Settings</a>
                                    --> 
                                    */}
                                        </div>
                                        :
                                        <div className="btn-group" role="group" aria-label="Button group">
                                            <button className="btn btn-success btn-ladda ladda-button" style={{ padding: 5, zIndex: 123 }} onClick={() => {
                                                this.props.getUserNeeds(this.state.filterPlaces, !this.state.getCompletedUserNeeds, 1); this.setState({ getCompletedUserNeeds: !this.state.getCompletedUserNeeds, useSocketOrders: 0 });
                                            }}>
                                                <div className="loader" style={{ display: 'inline-block', verticalAlign: 'text-bottom', visibility: this.props.loadingOrders ? 'visible' : 'hidden' }}></div>
                                                &nbsp;
                                                <span style={{ color: 'black' }}>{this.state.getCompletedUserNeeds ? this.props.translations.orders.hide_completed : this.props.translations.orders.show_completed}</span> &nbsp;&nbsp;&nbsp;
                                            </button>
                                        </div>
                                    }
                                </>
                                :
                                null
                            }

                            &nbsp;

                            <div className="btn-group" role="group" aria-label="Button group">
                                <button
                                    className="btn btn-warning btn-ladda ladda-button"
                                    style={{ padding: 5, color: 'white' }}
                                    onClick={() => this.setState({ visibleFilter: !this.state.visibleFilter })}
                                >
                                    {this.props.translations.common.filter}
                                </button>
                            </div>

                            &nbsp;

                            <div className="btn-group" role="group" aria-label="Button group">
                                <button
                                    className="btn btn-info btn-ladda ladda-button"
                                    style={{ padding: 0 }}
                                    // style={{ padding: 5, color: 'white', background: '#63c2de !important' }}
                                    onClick={() => this.setState({ visibleFilter: !this.state.visibleFilter })}
                                >
                                    <NavLink to={ADMIN_URL + `tableRegions`} className="btn label-blue">
                                        {this.props.translations.orders && this.props.translations.orders.push_notifications || ''}

                                    </NavLink>
                                </button>
                            </div>

                            &nbsp;

                            <div className="btn-group" role="group" aria-label="Button group">
                                <button
                                    className="btn btn-danger btn-ladda ladda-button"
                                    style={{ padding: 0 }}
                                    // style={{ padding: 5, color: 'white', background: '#63c2de !important' }}
                                    // onClick={() => this.setState({ visibleFilter: !this.state.visibleFilter })}
                                    onClick={() => this.openBulkDeleteOrderModal()}
                                >
                                    <span className="btn label-white">
                                        {this.props.translations.orders && this.props.translations.orders.delete_all || ''}
                                    </span>
                                    {/* <NavLink to={ADMIN_URL + `tableRegions`} className="btn">
                                        {this.props.translations.orders && this.props.translations.orders.delete_all || ''}

                                    </NavLink> */}

                                    {/* <button className="btn btn-pill btn-link" type="button" onClick={() => props.openDeleteOrderModal(props.order.id, props.order.place_order_number)}>
                    <i className="fas fa-trash"></i>
                </button> */}

                                </button>
                            </div>

                            &nbsp;

                            {Utils.getSettingsValue(this.props.settings, 'show_custom_print_orders_button_group_by_categories') == '1' ?
                                <div className="btn-group" role="group" aria-label="Button group">
                                    <button
                                        className="btn btn-info btn-ladda ladda-button"
                                        style={{ padding: 0 }}
                                        // style={{ padding: 5, color: 'white', background: '#63c2de !important' }}
                                        // onClick={() => this.setState({ visibleFilter: !this.state.visibleFilter })}
                                        onClick={() => this.handlePrintAllOrders(orderItems)}
                                    >
                                        <span className="btn label-white">
                                            {this.props.translations.orders && this.props.translations.orders.print_all_today || ''}
                                        </span>
                                        {/* <NavLink to={ADMIN_URL + `tableRegions`} className="btn">
                                        {this.props.translations.orders && this.props.translations.orders.delete_all || ''}

                                    </NavLink> */}

                                        {/* <button className="btn btn-pill btn-link" type="button" onClick={() => props.openDeleteOrderModal(props.order.id, props.order.place_order_number)}>
                                            <i className="fas fa-trash"></i>
                                        </button> */}

                                    </button>
                                </div>
                                :
                                null
                            }

                            {Utils.getSettingsValue(this.props.settings, 'show_option_for_managing_hour_busy') == '1' ?
                                <>
                                    {Utils.getSettingsValue(this.props.settings, 'enabled_busy_hour') == '1' ?
                                        < div className="btn-group" role="group" aria-label="Button group">
                                            <button
                                                className="btn btn-danger btn-ladda ladda-button"
                                                style={{ padding: 0, border: '1px solid #ccc', padding: 4, color: 'white' }}
                                                // style={{ padding: 5, color: 'white', background: '#63c2de !important' }}
                                                onClick={() => this.setHourBusy(0)}
                                            >
                                                Изключи ЧАС ПИК
                                            </button>
                                        </div>
                                        :
                                        <div className="btn-group" role="group" aria-label="Button group">
                                            <button
                                                className="btn btn-success btn-ladda ladda-button"
                                                style={{ padding: 0, border: '1px solid #ccc', padding: 4, color: 'white' }}
                                                // style={{ padding: 5, color: 'white', background: '#63c2de !important' }}
                                                onClick={() => this.setHourBusy(1)}
                                            >
                                                Включи ЧАС ПИК
                                            </button>
                                        </div>
                                    }
                                </>
                                :
                                null
                            }

                            &nbsp;

                            <i className={"nav-icon hoverable change-order-style-button " + (Utils.getOrdersStyleType() == 'row' ? 'icon-grid' : 'icon-list')} onClick={() => { Utils.changeOrdersStyleType(); this.setState({ activeTab: this.state.activeTab }) }} style={{
                                fontSize: 20,
                                background: 'white',
                                'borderRadius': '50%',
                                'verticalAlign': 'middle',
                                border: '1px solid #ccc',
                                padding: 9
                            }}></i>

                        </li>
                    </ol>


                    {Utils.getOrdersStyleType() != 'row' ?
                        <div style={{ textAlign: 'center', marginBottom: 20, fontWeight: 'bold' }}>{this.props.translations.common.bill}{Auth.hasAccess('PLACE_ADMIN') ? <>: {currSum} {(this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value ? this.props.settings.default_currency_suffix.value : 'лв.')}</> : null}</div>
                        :
                        null
                    }

                    <div className="container-fluid">
                        <div className="animated fadeIn">
                            <div className="row">
                                {this.state.visibleFilter ?
                                    <Filter
                                        makeFilter={this.makeFilter}
                                        translations={this.props.translations}
                                        statuses={statuses}
                                        types={currPlaceOrderTypes}
                                        onChange={this.onChange}
                                        onFieldChange={this.onFieldChange}
                                        onTableSelect={this.onTableSelect}
                                        onTableRemove={this.onTableRemove}
                                        onTableRegionSelect={this.onTableRegionSelect}
                                        onTableRegionRemove={this.onTableRegionRemove}
                                        productPrintGroups={this.props.productPrintGroups}

                                        tables={this.props.tables}
                                        tablesForOrders={this.props.tablesForOrders}
                                        tableRegions={this.props.tableRegions}
                                        tableRegionsForSelectedPlaces={this.props.tableRegionsForSelectedPlaces}
                                        preSelectedFilterTables={this.state.preSelectedFilterTables}
                                        preSelectedFilterTableRegions={this.state.preSelectedFilterTableRegions}
                                        filterPlaces={this.state.filterPlaces}
                                        allPlaces={this.props.places}
                                        onPlaceSelect={this.onPlaceSelect}
                                        onPlaceRemove={this.onPlaceRemove}
                                        onProductPrintGroupSelect={this.onProductPrintGroupSelect}
                                        onProductPrintGroupRemove={this.onProductPrintGroupRemove}
                                        filter={this.state}
                                        prodPrintGroups={this.state.prodPrintGroups}
                                    />
                                    :
                                    null
                                }



                                {Utils.getSettingsValue(this.props.settings, 'show_orders_info_table_with_unique_count') == 1 ?
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Количество</th>
                                                <th>Продукт</th>
                                                <th>Размер</th>
                                                <th>Категория</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {Object.entries(groupedOrderItems) */}

                                            {Object.entries(groupedOrderItems)
                                                // Sort by category, which is the third part of the split key
                                                .sort(([keyA], [keyB]) => {
                                                    const categoryA = keyA.split(' - ')[2]; // Category is the third part of the key
                                                    const categoryB = keyB.split(' - ')[2];
                                                    return categoryB.localeCompare(categoryA); // Reverse the order by swapping A and B
                                                })

                                                .map(([key, quantity], index) => {
                                                    const [productName, size, category] = key.split(' - '); // Split back product and category
                                                    return (
                                                        <tr key={index}>
                                                            <td>{quantity}</td>
                                                            <td>{productName}</td>
                                                            <td>{size}</td>
                                                            <td>{category}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                    :
                                    null
                                }

                                {/* <!-- /.col--> */}
                                <div className="col-lg-12 p-0">

                                    <MessagesContainer />

                                    <ul className={"nav nav-tabs " + "orders-style-" + Utils.getOrdersStyleType()} role="tablist" style={{ display: 'flex' }}>
                                        {/* Vsichki */}
                                        <li className="nav-item">
                                            <a className={"nav-link border-left-0 " + (this.state.activeTab == 1 ? "active" : "")} data-toggle="tab" onClick={() => this.setState({ activeTab: 1 })} role="tab" aria-controls="home">
                                                <b>{allItems.length}</b> {this.props.translations.orders.all}
                                            </a>
                                        </li>

                                        {/* Novi */}
                                        <li className="nav-item">
                                            <a className={"nav-link " + (this.state.activeTab == 2 ? "active" : "")} data-toggle="tab" onClick={() => setActiveTab(2, unreadOrderedItems)} role="tab" aria-controls="home">
                                                {orderedItems.length} {this.props.translations.orders.new} {unreadOrderedItems > 0 ? <span className="badge badge-pill badge-danger">{unreadOrderedItems}</span> : null}
                                            </a>
                                        </li>

                                        {/* Prigotveni/Chakashti */}
                                        {statuses.cooked_spot || statuses.cooked_delivery || statuses.cooked_preorder || statuses.cooked_room || this.props.ordertype == 'all' ?
                                            <li className="nav-item">
                                                <a className={"nav-link " + (this.state.activeTab == 3 ? "active" : "")} data-toggle="tab" onClick={() => this.setState({ activeTab: 3 })} role="tab" aria-controls="profile">
                                                    {cookedItems.length} &nbsp;
                                                    {this.props.ordertype == 'all' ?
                                                        <>{this.props.translations.orders.cooked}
                                                            {/* /{this.props.translations.common.waitings} */}
                                                        </>
                                                        :
                                                        (this.props.ordertype == 'spot' ?
                                                            <>{this.props.translations.orders.cooked}</>
                                                            :
                                                            this.props.ordertype == 'delivery' ?
                                                                <>{this.props.translations.orders.for_delivering}</>
                                                                :
                                                                this.props.ordertype == 'preorder' ?
                                                                    <>{this.props.translations.orders.waiting_for_pickup}</>
                                                                    :
                                                                    this.props.ordertype == 'room' ?
                                                                        <>{this.props.translations.orders.cooked}</>
                                                                        :
                                                                        null
                                                        )
                                                    }

                                                </a>
                                            </li>
                                            :
                                            null
                                        }

                                        {/* Servirani /dostavqshti se */}
                                        {(statuses.served_spot || statuses.on_the_way_delivery) && (this.props.ordertype != 'preorder') || this.props.ordertype == 'all' ?
                                            <li className="nav-item">
                                                <a className={"nav-link " + (this.state.activeTab == 4 ? "active" : "")} data-toggle="tab" onClick={() => this.setState({ activeTab: 4 })} role="tab" aria-controls="messages">
                                                    {deliveringServedItems.length} &nbsp;

                                                    {this.props.ordertype == 'all' ?
                                                        <>{((currPlaceOrderTypes.findIndex(p => p.type == "spot") > -1) || (currPlaceOrderTypes.findIndex(p => p.type == "room") > -1)) && ((currPlaceOrderTypes.findIndex(p => p.type == "delivery") > -1) || (currPlaceOrderTypes.findIndex(p => p.type == "preorder") > -1)) ? this.props.translations.orders.served + '/' + this.props.translations.orders.delivering : ((currPlaceOrderTypes.findIndex(p => p.type == "spot") > -1) || (currPlaceOrderTypes.findIndex(p => p.type == "room") > -1)) ? this.props.translations.orders.served : this.props.translations.orders.delivering}</>
                                                        :
                                                        this.props.ordertype == 'spot' ?
                                                            <>{this.props.translations.orders.served}</>
                                                            :
                                                            this.props.ordertype == 'delivery' ?
                                                                <>{this.props.translations.orders.for_delivering}</>
                                                                :
                                                                null
                                                    }

                                                </a>
                                            </li>
                                            :
                                            null
                                        }

                                        {/* Dostaveni / Plateni */}
                                        <li className="nav-item">
                                            <a className={"nav-link " + (this.state.activeTab == 5 ? "active" : "")} data-toggle="tab" onClick={() => this.setState({ activeTab: 5 })} role="tab" aria-controls="messages">
                                                {paidItems.length} &nbsp;

                                                {this.props.ordertype == 'all' ?
                                                    <>{this.props.translations.orders.paid}
                                                        {/* /{this.props.translations.orders.delivered} */}
                                                    </>
                                                    :
                                                    (this.props.ordertype == 'spot' ?
                                                        <>{this.props.translations.orders.paid}</>
                                                        :
                                                        this.props.ordertype == 'delivery' ?
                                                            <>{this.props.translations.orders.delivered}</>
                                                            :
                                                            this.props.ordertype == 'preorder' ?
                                                                <>{this.props.translations.orders.paid}</>
                                                                :
                                                                this.props.ordertype == 'room' ?
                                                                    <>{this.props.translations.orders.paid}</>
                                                                    :
                                                                    null
                                                    )
                                                }
                                            </a>
                                        </li>

                                        {/* Bill wanted / User needs */}
                                        {(this.props.ordertype == 'all' || this.props.ordertype == 'spot' || this.props.ordertype == 'room') && ((currPlaceOrderTypes.findIndex(p => p.type == "spot") > -1) || (currPlaceOrderTypes.findIndex(p => p.type == "room") > -1)) ?
                                            <React.Fragment>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + (this.state.activeTab == 6 ? "active" : "")} data-toggle="tab" onClick={() => setActiveTab(6)} role="tab" aria-controls="messages">
                                                        <i className="fas fa-dollar "></i> {parseInt(unreadBillWantedItems) > 0 ? <span className="badge badge-pill badge-danger">{unreadBillWantedItems}</span> : null}
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + (this.state.activeTab == 7 ? "active" : "")} data-toggle="tab" onClick={() => setActiveTab(7)} role="tab" aria-controls="messages">
                                                        {/* <i className="icon-bell"></i> {parseInt(unreadUserNeeds) > 0 ? <span className="badge badge-pill badge-danger">{unreadUserNeeds}</span> : null} */}

                                                        {userNeeds.length > 0 ? userNeeds.length : null}
                                                        &nbsp;
                                                        <i className="icon-bell"></i>
                                                        &nbsp;
                                                        {userNeeds.length > 0 ?
                                                            <React.Fragment>
                                                                {parseInt(allUnreadUserNeeds) > 0 ?
                                                                    <span className="badge badge-pill badge-danger">{allUnreadUserNeeds}</span>
                                                                    :
                                                                    null
                                                                }
                                                            </React.Fragment>
                                                            :
                                                            null
                                                        }


                                                    </a>
                                                </li>
                                            </React.Fragment>
                                            :
                                            null
                                        }


                                        {/* Budeshti */}
                                        {(this.props.ordertype == 'all' || this.props.ordertype == 'delivery' || this.props.ordertype == 'preorder') && ((currPlaceOrderTypes.findIndex(p => p.type == "delivery") > -1) || (currPlaceOrderTypes.findIndex(p => p.type == "preorder") > -1)) ?
                                            <React.Fragment>
                                                <li className="nav-item">
                                                    <a className={"nav-link " + (this.state.activeTab == 8 ? "active" : "")} data-toggle="tab" onClick={() => setActiveTab(8)} role="tab" aria-controls="messages">
                                                        {futureItems.length} &nbsp;
                                                        {this.props.translations.orders.future}
                                                    </a>
                                                </li>
                                            </React.Fragment>
                                            :
                                            null
                                        }


                                    </ul>

                                    {Utils.getOrdersStyleType() == 'row' ?
                                        // ROW VIEW
                                        <div className="card" >

                                            <div className=""> {/* card-body */}

                                                <div className="tab-content border-right-0 border-bottom-0 border-left-0" style={{ borderTop: 0 }}>
                                                    <div className="tab-pane p-0 active" role="tabpanel">
                                                        <table className="table table-responsive-sm table-striped orders" style={{ width: '100%', tableLayout: 'fixed' }}>
                                                            <thead>
                                                                <tr>
                                                                    {this.state.activeTab != 6 ?
                                                                        <th style={{ "width": "4%" }}>№</th>
                                                                        :
                                                                        null
                                                                    }
                                                                    {this.state.activeTab != 6 && this.state.activeTab != 7 ?
                                                                        <>
                                                                            <th style={{ "width": "11%" }} className="no-padding">{this.props.translations.common.status}</th>
                                                                            <th style={{ "width": "24%" }}>{this.props.translations.common.client}</th>
                                                                            {/* <th>{this.props.translations.orders.client_address}</th> */}
                                                                            {/* Added for the place Filter (Това което иска Хотел Орфей - да вижда поръчки от мн обекти) */}
                                                                            {this.props.places && this.props.places.length > 1 ?
                                                                                <React.Fragment>
                                                                                    <th style={{ "width": "37%" }}>{this.props.translations.orders.products}</th>
                                                                                    <th style={{ "width": "8%" }}>Обект</th>
                                                                                </React.Fragment>
                                                                                :
                                                                                <th style={{ "width": "45%" }}>{this.props.translations.orders.products}</th>
                                                                            }
                                                                            {/* <th>{this.props.translations.orders.type}</th> */}
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                    {this.state.activeTab == 6 ? <th>{this.props.translations.common.table}</th> : null}
                                                                    {this.state.activeTab == 6 ? <th>{this.props.translations.common.hour}</th> : null}
                                                                    {this.state.activeTab == 6 ? <th>{this.props.translations.orders.payment_type}</th> : null}
                                                                    {this.state.activeTab == 6 ? <th>{this.props.translations.common.bill}</th> : null}
                                                                    {this.state.activeTab == 7 ? <th>{this.props.translations.orders.user_needs}</th> : null}
                                                                    {this.state.activeTab == 7 ? <th>{this.props.translations.common.table}</th> : null}
                                                                    {/* {this.props.ordertype == 'spot' ? <th>{this.props.translations.common.table}</th> : null} */}
                                                                    {/* <th>{this.state.activeTab == 6 ? this.props.translations.orders.update_date : this.props.translations.orders.order_date}</th> */}
                                                                    {/* {this.state.activeTab == 6 ? <th>{this.props.translations.orders.payment_type}</th> : null} */}
                                                                    {this.state.activeTab != 7 && this.state.activeTab != 6 ?
                                                                        <th style={{ "width": "16%" }} className="no-padding" title="Общата сума за всички филтрирани записи">{this.props.translations.common.bill}{Auth.hasAccess('PLACE_ADMIN') ? <>: {currSum} {(this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value ? this.props.settings.default_currency_suffix.value : 'лв.')}</> : null}</th>
                                                                        :
                                                                        null
                                                                    }

                                                                    {/* (Auth.hasAccess(this.props.userRole, "PLACE_ADMIN") && */}
                                                                    {/* {this.state.activeTab != 6 && this.state.activeTab != 7 ? <th></th> : null} */}

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/*  */}
                                                                {this.state.activeTab == 7 ?
                                                                    Object.values(userNeeds).map((u, index) => {
                                                                        if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                                            start_count++;
                                                                            return <ListEachUserNeeds
                                                                                key={u.id}
                                                                                userNeeds={u}
                                                                                statuses={statuses}
                                                                                updateUserNeedsStatusById={this.props.updateUserNeedsStatusById}
                                                                                // markAsCompleted={this.markAsCompleted}
                                                                                translations={this.props.translations}
                                                                            />
                                                                        }
                                                                    })
                                                                    :
                                                                    (this.state.activeTab == 6 ?
                                                                        Object.values(billWantedTableIds).reverse().map((b, index) => {
                                                                            if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                                                start_count++;
                                                                                return <ListEachBillWanted
                                                                                    tableId={b}
                                                                                    count={start_count}
                                                                                    allBillWantedItems={billWantedItems}
                                                                                    translations={this.props.translations}
                                                                                    updateOrderByTableId={this.updateOrderByTableId}
                                                                                />
                                                                            }
                                                                        })
                                                                        :
                                                                        Object.values(orderItems).reverse().map((order, index) => {
                                                                            const self = this;

                                                                            // console.log('IN_PRINT_BOX prodPrintGroups', prodPrintGroups);
                                                                            // console.log('IN_PRINT_BOX this.props.productPrintGroups', this.props.productPrintGroups);
                                                                            // console.log('IN_PRINT_BOX order.printedGroups', order.printedGroups);
                                                                            // console.log('IN_PRINT_BOX order.printed', order.printed);
                                                                            // console.log('IN_PRINT_BOX ------');

                                                                            return (
                                                                                <React.Fragment key={index}>

                                                                                    {/* Duplicated orders - CLIENT MARK */}
                                                                                    {(order.duplicate_printing_done_mark_for_client != 1 && (Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order_mark_for_client') && (parseInt(Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order_mark_for_client')) >= 1))) ?
                                                                                        <>
                                                                                            {Utils.generateArrayWithLength(parseInt(Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order_mark_for_client'))).map((product) =>
                                                                                                <>
                                                                                                    {
                                                                                                        // tova sushto da ide na gorniq utils 
                                                                                                        this.state.printedDuplicateOrdersClientMark && (!this.state.printedDuplicateOrdersClientMark[order.place_order_number] || (this.state.printedDuplicateOrdersClientMark[order.place_order_number] && this.state.printedDuplicateOrdersClientMark[order.place_order_number] != parseInt(Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order_mark_for_client'))))
                                                                                                            && localStorage.getItem('tabl_printer') == 1 && (Utils.getSettingsValue(settings, 'allow_auto_print_orders') == 1) ?

                                                                                                            <div style={{ display: 'none' }}>
                                                                                                                <ReactToPrint
                                                                                                                    content={() => this.orderRefsDuplicatedOrdersClientMark[order.place_order_number]}
                                                                                                                    // onBeforePrint={() => { console.log('printer printing - DUPLICATE BEFORE' + product) }}
                                                                                                                    onAfterPrint={() => {
                                                                                                                        self.updatePrintedTimesClientMark(order.id, order.printed_times_mark_for_client, parseInt(Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order_mark_for_client')));
                                                                                                                        // this.state.printedDuplicateOrdersClientMark[order.place_order_number]

                                                                                                                        let printedDuplicateOrdersClientMark = this.state.printedDuplicateOrdersClientMark;
                                                                                                                        // let printedDuplicateOrdersClientMark = this.state.printedDuplicateOrdersClientMark && this.state.printedDuplicateOrdersClientMark[order.place_order_number] ? this.state.printedDuplicateOrdersClientMark[order.place_order_number] : 0;
                                                                                                                        printedDuplicateOrdersClientMark[order.place_order_number] = (printedDuplicateOrdersClientMark[order.place_order_number] || 0) + 1;
                                                                                                                        this.setState({ printedDuplicateOrdersClientMark: printedDuplicateOrdersClientMark });
                                                                                                                        console.log('DUPLICATE CLIENT MARK#' + product + '(B) printing orderId:', order.id, ' (' + order.place_order_number + ')'); // pls do not remove this :)
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <PrintContextConsumer>
                                                                                                                        {({ handlePrint }) => {
                                                                                                                            // if (self.state.printingState == false) {
                                                                                                                            setTimeout(() => handlePrint(), 5500)
                                                                                                                            // }

                                                                                                                        }}
                                                                                                                    </PrintContextConsumer>
                                                                                                                </ReactToPrint>
                                                                                                                <ComponentToPrint
                                                                                                                    ref={el => (this.orderRefsDuplicatedOrdersClientMark[order.place_order_number] = el)}
                                                                                                                    settings={this.props.settings}
                                                                                                                    order={order}
                                                                                                                    all_products={this.props.all_products}
                                                                                                                    regions={this.props.regions}
                                                                                                                    ingredients={this.props.ingredients}
                                                                                                                    prodPrintGroups={prodPrintGroups}
                                                                                                                    addMinutesToTime={this.addMinutesToTime}
                                                                                                                />
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                    }



                                                                                    {/* SINGLE CLIENT MARK */}
                                                                                    {
                                                                                        (
                                                                                            Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order_mark_for_client') == 0
                                                                                            || Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order_mark_for_client') == ''
                                                                                            || Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order_mark_for_client') == null
                                                                                            || Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order_mark_for_client') == 'null'
                                                                                        )
                                                                                            &&
                                                                                            (
                                                                                                (
                                                                                                    order.printed == false
                                                                                                    && !(this.state.printedOrders).includes(order.place_order_number)
                                                                                                    && ((prodPrintGroups && prodPrintGroups == 0) || !prodPrintGroups)
                                                                                                )
                                                                                                ||
                                                                                                (
                                                                                                    this.props.productPrintGroups && this.props.productPrintGroups.length > 0
                                                                                                    && prodPrintGroups && prodPrintGroups.length > 0 && (!order.printedGroups || !(prodPrintGroups.every(a => order.printedGroups.includes(a))))
                                                                                                )

                                                                                            )
                                                                                            && localStorage.getItem('tabl_printer') == 1
                                                                                            && (Utils.getSettingsValue(settings, 'allow_auto_print_orders') == 1
                                                                                            )

                                                                                            ?
                                                                                            <div style={{ display: 'none' }}>
                                                                                                <ReactToPrint
                                                                                                    content={() => this.orderRefs[order.place_order_number]}
                                                                                                    onAfterPrint={() => {
                                                                                                        if (prodPrintGroups && prodPrintGroups.length > 0 && this.props.productPrintGroups && this.props.productPrintGroups.length > 0) {
                                                                                                            self.updatePrinted(order.id, prodPrintGroups);
                                                                                                        } else {
                                                                                                            let printedOrders = this.state.printedOrders;
                                                                                                            printedOrders.push(order.place_order_number);
                                                                                                            this.setState({ printedOrders: printedOrders });
                                                                                                            console.log('(B) printing orderId:', order.id, ' (' + order.place_order_number + ')'); // pls do not remove this :)
                                                                                                            self.updatePrinted(order.id);
                                                                                                        }


                                                                                                    }}
                                                                                                >
                                                                                                    <PrintContextConsumer>
                                                                                                        {({ handlePrint }) => {
                                                                                                            // if (self.state.printingState == false) {
                                                                                                            setTimeout(() => handlePrint(), 3500)
                                                                                                            // }

                                                                                                        }}
                                                                                                    </PrintContextConsumer>
                                                                                                </ReactToPrint>
                                                                                                <ComponentToPrint
                                                                                                    ref={el => (this.orderRefs[order.place_order_number] = el)}
                                                                                                    settings={this.props.settings}
                                                                                                    order={order}
                                                                                                    all_products={this.props.all_products}
                                                                                                    regions={this.props.regions}
                                                                                                    ingredients={this.props.ingredients}
                                                                                                    prodPrintGroups={prodPrintGroups}
                                                                                                    addMinutesToTime={this.addMinutesToTime}
                                                                                                />
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                    }


                                                                                    {/* Duplicated orders - KITCHEN MARK */}
                                                                                    {(order.duplicate_printing_done != 1 && (Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order') && (parseInt(Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order')) >= 1))) ?
                                                                                        <>
                                                                                            {Utils.generateArrayWithLength(parseInt(Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order'))).map((product) =>
                                                                                                <>
                                                                                                    {
                                                                                                        // tova sushto da ide na gorniq utils 
                                                                                                        this.state.printedDuplicateOrders && (!this.state.printedDuplicateOrders[order.place_order_number] || (this.state.printedDuplicateOrders[order.place_order_number] && this.state.printedDuplicateOrders[order.place_order_number] != parseInt(Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order'))))
                                                                                                            && localStorage.getItem('tabl_printer') == 1 && (Utils.getSettingsValue(settings, 'allow_auto_print_orders') == 1) ?

                                                                                                            <div style={{ display: 'none' }}>
                                                                                                                <ReactToPrint
                                                                                                                    content={() => this.orderRefsDuplicatedOrders[order.place_order_number]}
                                                                                                                    // onBeforePrint={() => { console.log('printer printing - DUPLICATE BEFORE' + product) }}
                                                                                                                    onAfterPrint={() => {
                                                                                                                        self.updatePrintedTimes(order.id, order.printed_times, parseInt(Utils.getSettingsValue(this.props.settings, 'order_print_duplicate_order')));
                                                                                                                        // this.state.printedDuplicateOrders[order.place_order_number]

                                                                                                                        let printedDuplicateOrders = this.state.printedDuplicateOrders;
                                                                                                                        // let printedDuplicateOrders = this.state.printedDuplicateOrders && this.state.printedDuplicateOrders[order.place_order_number] ? this.state.printedDuplicateOrders[order.place_order_number] : 0;
                                                                                                                        printedDuplicateOrders[order.place_order_number] = (printedDuplicateOrders[order.place_order_number] || 0) + 1;
                                                                                                                        this.setState({ printedDuplicateOrders: printedDuplicateOrders });
                                                                                                                        console.log('DUPLICATE #' + product + '(B) printing orderId:', order.id, ' (' + order.place_order_number + ')'); // pls do not remove this :)
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <PrintContextConsumer>
                                                                                                                        {({ handlePrint }) => {
                                                                                                                            // if (self.state.printingState == false) {
                                                                                                                            setTimeout(() => handlePrint(), 5500)
                                                                                                                            // }

                                                                                                                        }}
                                                                                                                    </PrintContextConsumer>
                                                                                                                </ReactToPrint>
                                                                                                                <ComponentToPrintDuplicatedOrder
                                                                                                                    ref={el => (this.orderRefsDuplicatedOrders[order.place_order_number] = el)}
                                                                                                                    settings={this.props.settings}
                                                                                                                    order={order}
                                                                                                                    all_products={this.props.all_products}
                                                                                                                    regions={this.props.regions}
                                                                                                                    ingredients={this.props.ingredients}
                                                                                                                    prodPrintGroups={prodPrintGroups}
                                                                                                                />
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                        :
                                                                                        null
                                                                                    }



                                                                                    {
                                                                                        (index >= start_offset && start_count <= ITEMS_PER_PAGE && ++start_count) ?
                                                                                            <React.Fragment>
                                                                                                <ListEachOrder
                                                                                                    key={index}
                                                                                                    index={index}
                                                                                                    order={order}
                                                                                                    orderComment={order.order_comment ? JSON.parse(order.order_comment) : ''}
                                                                                                    deliveryTime={order.delivery_time ? moment(order.delivery_time).format('DD.MM / HH:mm') : ''}
                                                                                                    pickupTime={order.pickup_time ? moment(order.pickup_time).format('DD.MM / HH:mm') : ''}
                                                                                                    statuses={this.props.ordertype == 'all' ? this.getActiveStatuses(order.ordertypeId) : statuses}
                                                                                                    translations={this.props.translations}
                                                                                                    updateStatus={this.updateStatus}
                                                                                                    userRole={this.props.userRole}
                                                                                                    openDeleteOrderModal={this.openDeleteOrderModal}
                                                                                                    openRejectOrderModal={this.openRejectOrderModal}
                                                                                                    openWarehouseErrorsModal={this.openWarehouseErrorsModal}
                                                                                                    settings={this.props.settings}
                                                                                                    all_products={this.props.all_products}
                                                                                                    ingredients={this.props.ingredients}
                                                                                                    ordertype={this.props.ordertype}
                                                                                                    regions={this.props.regions}
                                                                                                    categoriesHierarchy={this.props.categoriesHierarchy}
                                                                                                    updateVariantStatus={this.updateVariantStatus}
                                                                                                    updatePrinted={this.updatePrinted}
                                                                                                    allPlaces={this.props.places}
                                                                                                    listProducts={this.props.listProducts}
                                                                                                    listIngredients={this.props.listIngredients}
                                                                                                    changeTimeUntilOrderReady={(e, orderId) => this.changeTimeUntilOrderReady(e, orderId, order)}
                                                                                                    banUnbanAnonUser={this.banUnbanAnonUser}
                                                                                                    prodPrintGroups={this.state.prodPrintGroups}
                                                                                                    addMinutesToTime={this.addMinutesToTime}
                                                                                                    resendOrderToDeliverySystem={this.resendOrderToDeliverySystem}
                                                                                                    openConfirmModal={this.props.openConfirmModal}
                                                                                                    currPlace={currPlace}
                                                                                                    orderIdsToPrint={this.state.orderIdsToPrint}
                                                                                                />

                                                                                            </React.Fragment>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        :

                                        // COLUMN VIEW
                                        <div className="row margin10" >
                                            {this.state.activeTab == 7 ?
                                                Object.values(userNeeds).map((u, index) => {
                                                    if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;
                                                        return <ListEachUserNeedsBox
                                                            key={u.id}
                                                            userNeeds={u}
                                                            statuses={statuses}
                                                            updateUserNeedsStatusById={this.props.updateUserNeedsStatusById}
                                                            // markAsCompleted={this.markAsCompleted}
                                                            translations={this.props.translations}
                                                        />
                                                    }
                                                })
                                                :
                                                (this.state.activeTab == 6 ?
                                                    Object.values(billWantedTableIds).reverse().map((b, index) => {
                                                        if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                            start_count++;
                                                            return <ListEachBillWantedBox
                                                                tableId={b}
                                                                count={start_count}
                                                                allBillWantedItems={billWantedItems}
                                                                translations={this.props.translations}
                                                                updateOrderByTableId={this.updateOrderByTableId}
                                                            />
                                                        }
                                                    })
                                                    :
                                                    Object.values(orderItems).reverse().map((order, index) => {
                                                        const self = this;
                                                        // console.log('IN_PRINT_BOX prodPrintGroups', prodPrintGroups);
                                                        // console.log('IN_PRINT_BOX this.props.productPrintGroups', this.props.productPrintGroups);
                                                        // console.log('IN_PRINT_BOX order.printedGroups', order.printedGroups);
                                                        // console.log('IN_PRINT_BOX order.printed', order.printed);
                                                        // console.log('IN_PRINT_BOX ------');
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    (Utils.getSettingsValue(settings, 'allow_auto_print_orders') == 1)
                                                                        && localStorage.getItem('tabl_printer') == 1
                                                                        &&
                                                                        (
                                                                            (
                                                                                order.printed == false
                                                                                && !(this.state.printedOrders).includes(order.place_order_number)
                                                                                && ((prodPrintGroups && prodPrintGroups == 0) || !prodPrintGroups)
                                                                            )
                                                                            ||
                                                                            (
                                                                                this.props.productPrintGroups && this.props.productPrintGroups.length > 0
                                                                                && prodPrintGroups && prodPrintGroups.length > 0 && (!order.printedGroups || !(prodPrintGroups.every(a => order.printedGroups.includes(a))))
                                                                            )
                                                                        )
                                                                        ?
                                                                        <div style={{ display: 'none' }}>
                                                                            <ReactToPrint
                                                                                content={() => this.orderRefs[order.place_order_number]}
                                                                                onAfterPrint={() => {
                                                                                    if (prodPrintGroups && prodPrintGroups.length > 0 && this.props.productPrintGroups && this.props.productPrintGroups.length > 0) {
                                                                                        self.updatePrinted(order.id, prodPrintGroups);
                                                                                    } else {
                                                                                        let printedOrders = this.state.printedOrders;
                                                                                        printedOrders.push(order.place_order_number);
                                                                                        this.setState({ printedOrders: printedOrders });
                                                                                        console.log('(A) printing orderId:', order.id, ' (' + order.place_order_number + ')'); // pls do not remove this :)
                                                                                        self.updatePrinted(order.id);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <PrintContextConsumer>
                                                                                    {({ handlePrint }) => {
                                                                                        // if (self.state.printingState == false) {
                                                                                        setTimeout(() => handlePrint(), 3500)
                                                                                        // }
                                                                                    }}
                                                                                </PrintContextConsumer>
                                                                            </ReactToPrint>
                                                                            <ComponentToPrint
                                                                                ref={el => (this.orderRefs[order.place_order_number] = el)}
                                                                                settings={this.props.settings}
                                                                                order={order}
                                                                                all_products={this.props.all_products}
                                                                                regions={this.props.regions}
                                                                                ingredients={this.props.ingredients}
                                                                                prodPrintGroups={prodPrintGroups}
                                                                                addMinutesToTime={this.addMinutesToTime}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    (index >= start_offset && start_count <= ITEMS_PER_PAGE && ++start_count) ?
                                                                        <React.Fragment>
                                                                            <ListEachOrderBox
                                                                                key={order.id}
                                                                                index={index}
                                                                                billWantedTableIds={billWantedTableIds}
                                                                                allBullWantedItems={billWantedItems}
                                                                                deliveryTime={order.delivery_time ? moment(order.delivery_time).format('DD.MM / HH:mm') : ''}
                                                                                pickupTime={order.pickup_time ? moment(order.pickup_time).format('DD.MM / HH:mm') : ''}
                                                                                order={order}
                                                                                settings={this.props.settings}
                                                                                orderComment={order.order_comment ? JSON.parse(order.order_comment) : ''}
                                                                                // statuses={statuses}
                                                                                openDeleteOrderModal={this.openDeleteOrderModal}
                                                                                openRejectOrderModal={this.openRejectOrderModal}
                                                                                translations={this.props.translations}
                                                                                updateStatus={this.updateStatus}
                                                                                updateVariantStatus={this.updateVariantStatus}
                                                                                userRole={this.props.userRole}
                                                                                all_products={this.props.all_products}
                                                                                categoriesHierarchy={this.props.categoriesHierarchy}
                                                                                ingredients={this.props.ingredients}
                                                                                statuses={this.props.ordertype == 'all' ? this.getActiveStatuses(order.ordertypeId) : statuses}
                                                                                regions={this.props.regions}
                                                                                updatePrinted={this.updatePrinted}
                                                                                changeTimeUntilOrderReady={this.changeTimeUntilOrderReady}
                                                                                banUnbanAnonUser={this.banUnbanAnonUser}
                                                                                prodPrintGroups={this.state.prodPrintGroups}
                                                                                addMinutesToTime={this.addMinutesToTime}
                                                                            />
                                                                        </React.Fragment>
                                                                        :
                                                                        null
                                                                }
                                                            </React.Fragment>
                                                        )

                                                    })
                                                )
                                            }
                                        </div>

                                    }

                                    <Pagination
                                        className="pagination"
                                        itemClass="page-item"
                                        activePage={current_page}
                                        activeClass="active"
                                        linkClass="page-link"
                                        prevPageText="<"
                                        nextPageText=">"
                                        firstPageText="<<"
                                        lastPageText=">>"
                                        itemsCountPerPage={ITEMS_PER_PAGE}
                                        totalItemsCount={items_count}
                                        pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                        onChange={this.handlePageChange}
                                    />

                                    {/* <!-- /.col--> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </main >
        )
    }
}






// const mapStateToProps = (state, ownProps) => {
// 	let id = ownProps.match.params.id;

// 	let item = Object.values(state.alergens.alergens).find(i => i.id == id);
// 	if (!item) item = {
// 		id: '',
// 		name: '',
// 		image: '',
// 		translations: { }
// 	};
// 	return {
// 		item: item,
// 		errors: state.alergens.errors,
// 		translations: state.lang,
// 		languages: state.languages.languages || { },
// 		currLang: "bg",
// 	}
// };

// export default connect(mapStateToProps, {addEditAlergen, listAlergens, addErrorMessages})(EditCategoryPage);
//

const mapStateToProps = (state, ownProps) => {
    let ordertype = ownProps.match.params.ordertype;

    let ordertypeId = 0;
    if (state.places.places) {
        let currPlace = state.places.places.find(p => p.id == Auth.getUserPlace());
        if (currPlace) {
            let foundOrdertype = currPlace.ordertypes.find(ot => ot.type === ordertype);

            if (foundOrdertype) {
                ordertypeId = foundOrdertype.id;
            }
        }
    }

    return {
        ordertype: ordertype,
        ordertypeId: ordertypeId,
        orders: state.orders.orders || [],
        loadingOrders: state.orders.loading_orders,
        user_needs: state.orders.user_needs || [],
        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        userRole: state.auth.user.role || "",
        settings: state.settings.settings,
        all_products: state.products.products || [],
        ingredients: state.ingredients.ingredients || [],
        tables: state.tables.tables || [],
        tablesForOrders: state.tables.tables_for_orders || [], //may contain tables for multiple places - thats why its separated
        tableRegions: state.tableRegions.tableRegions || [],
        tableRegionsForSelectedPlaces: state.tableRegions.tableRegionsForSelectedPlaces || [],
        places: state.places.places || [],
        regions: state.regions.regions || [],
        orderActiveStatuses: state.settings && state.settings.settings && state.settings.settings && state.settings.settings.order_active_statuses && state.settings.settings.order_active_statuses['value'] || {},
        categoriesHierarchy: state.categoriesHierarchy.categories,
        soundActivated: state.admin.soundActivated,
        productPrintGroups: state.productPrintGroups.productPrintGroups
        // orderStyleType: state.admin.orderStyleType,
        // ordertypesByName: ordertypesByName || []
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({
            listOrders, getUserNeeds, updateOrderStatus, updateProductVariantStatus, removeOrder, removeBulkOrdersForToday,
            updateUserNeedsStatusById, updateOrderByTableId, updateOrderPrinted, updateOrderPrintedByPlace, updateSeen,
            updateOrderById, setSoundActivation, changeOrdersStyleType, addErrorMessages, showLoading, hideLoading,
            clearMessages, listProducts, listIngredients, openConfirmModal, listTableRegions, listtableRegionsForSelectedPlaces, listTablesForOrders, listProductPrintGroups,
            updateAnonUser, addSuccessMessage, editSetting, resendOrderToDeliverySystem
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOrdersPage);
