import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { addReservation } from '../actions/reservations.actions';
import { listPlaces } from './../../places/actions/places.actions';
import { listRooms } from './../../rooms/actions/rooms.actions';
import { listReservationTables } from './../../tables/actions/tables.actions';
import ReservationForm from './ReservationForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import queryString from 'query-string'
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';
import { listTables } from '../../../actions/tablesActions';

class AddReservationPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            reservation: {
                date_time_from: '',
                place_id: 0,
                room_id: 0,
                people_count: '',
                client_name: '',
                client_phone: '',
                status: '',
                description: '',
                menu_included: '',
                paid_ammount: '',
            },
            rooms: [],
            tables: [],
            tableIds: [],
            tablesCount: 0,
            errors: []
        }

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setRoom(nextProps);
    }

    componentWillMount() {
        this.props.listReservationTables();
    }

    componentDidMount() {
        this.props.listTables();
        this.setRoom();

        const params = queryString.parse(this.props.location.search);

        if (typeof params.minutes != "undefined") {
            params.minutes = params.minutes.length <= 1 ? "0" + params.minutes : params.minutes;
        }

        let paramsDate = params.date + " " + params.hour + ":" + params.minutes;


        let reservation = {
            id: '',
            date_time_from: params.date ? paramsDate : '',
            place_id: params.place_id ? params.place_id : 0,
            room_id: params.room_id ? params.room_id : 0,
            people_count: 2,
            client_name: '',
            client_phone: '',
            status: '',
            description: params.description ? params.description : '',
            menu_included: params.menu_included ? params.menu_included : '',
            paid_ammount: params.paid_ammount ? params.paid_ammount : '',
        };

        if (params.table_id) {
            this.addTable(params.table_id);
        }

        this.setState({ reservation: reservation });
    }

    setRoom = (nextProps) => {
        if (typeof nextProps == "undefined") {
            nextProps = this.props;
        }
        if (this.state.reservation.room_id == 0) {

            let places = nextProps.allPlaces;
            let placeId = places[Object.keys(places)[0]] ? places[Object.keys(places)[0]]['id'] : 0;
            if (placeId != 0) {
                let rooms = Object.values(nextProps.allRooms).filter(room => room.place_id == placeId);
                // console.log('NEXTTTT', rooms);

                let firstRoomId = rooms.length > 0 ? rooms[0].id : 0
                let res = this.state.reservation;
                res.place_id = placeId;
                res.room_id = firstRoomId;

                this.setState({ reservation: res, rooms: rooms });
            }
        } else if (this.state.reservation.place_id !== 0) {
            let placeId = this.state.reservation.place_id;
            let rooms = Object.values(nextProps.allRooms).filter(room => room.place_id == placeId);
            // console.log('NEXTTTT', rooms);


            this.setState({ rooms: rooms });
        }

    }

    // onChange(e) {
    // 	this.setState({ [e.target.name]: e.target.value });
    //     console.log('this.state',this.state);
    // }

    onChange = (e) => {
        let stateObject = Utils.onChange(e);

        let resState = this.state.reservation;
        resState[e.target.name] = stateObject[e.target.name];

        this.setState({ reservation: resState });
    }



    onReservationChange = e => {
        let res = this.state.reservation;
        res[e.target.name] = e.target.value;
        this.setState({ reservation: res });
    }

    onChangeCheckbox(e) {
        // let stateObject = Utils.onChange(e);
        // console.log('stateObject',stateObject);
        let resState = this.state.reservation;
        resState[e.target.name] = e.target.checked;
        console.log('resState', resState);
        this.setState({ reservation: resState });

        // this.setState({ [e.target.name]: e.target.checked });
    }

    onPlaceChange = (e) => {
        let placeId = e.target.options[e.target.selectedIndex].value;
        let roomsArr = Object.values(this.props.allRooms);
        let rooms = roomsArr.filter(room => room.place_id == placeId);

        let res = this.state.reservation;
        res.place_id = placeId;
        res.room_id = rooms[0].id;
        this.setState({ reservation: res, rooms: rooms });
    }

    onTableSelectChange = (e) => {
        const key = parseInt(e.target.name);
        let foundIndex = parseInt(this.state.tables.findIndex(i => i.key === key));
        // console.log(this.state.tables, typeof(key), foundIndex);return;

        let tableId = e.target.options[e.target.selectedIndex].value;
        let newTable = { key: key, value: tableId }
        let tables = this.state.tables;
        // console.log("FOUND ", tables[foundIndex], foundIndex)
        tables[foundIndex] = newTable;
        // console.log('TABLE ', tables);

        this.setState({ tables: tables })
    }

    addTable = (id = 0) => {
        console.log('id',id);
        let val = '';
        if (id != 0) {
            val = id;
        }
        let tablesCount = this.state.tablesCount;
        let newTable = { key: tablesCount, value: '' };
        console.log('newTable',newTable);
        tablesCount++;
        const tables = [...this.state.tables, newTable];
        this.setState({ tables: tables, tablesCount: tablesCount })
    }

    addTables = (ids = []) => {
        let val = '';
        if (ids.length > 0) {
            val = ids;
        }
        let tablesCount = this.state.tablesCount;

        let newTables = []
        let existingTables = [...this.state.tables]
        const tablesArray = val.split(',');
        for (let i = 0; i < tablesArray.length; i++) {
            // props.addTables(tablesArray[i]);
            tablesCount++;
            existingTables.push({ key: tablesCount, value: tablesArray[i] });

            console.log('adding table:', tablesArray[i]);
        }

        const existingAndNewTables = existingTables;

        this.setState({ tables: existingAndNewTables, tablesCount: tablesCount })
    }


    removeTable = (key) => {
        const filteredTables = this.state.tables.filter(t => {
            return t.key !== key
        })
        this.setState({ tables: filteredTables })
    }

    validate = () => {
        let errors = [];
        console.log('this.state.reservation', this.state.reservation);
        if (this.state.reservation.date_time_from.length === 0) {
            errors.push("Невалидна дата на резервацията");
        }

        if (this.state.reservation.people_count <= 0) {
            errors.push("Невалиден брой хора. Трябва да е повече от 0");
        }

        if (this.state.reservation.client_name.length === 0) {
            errors.push("Невалидно име");
        }

        if (this.state.reservation.client_phone.length === 0) {
            errors.push("Невалиден телефон");
        }


        if (this.state.tables && this.state.tables[0] && this.state.tables[0].value == 0) {
            errors.push("Моля изберете валидна маса");
        }


        return errors;
    }

    onSubmit(e) {
        e.preventDefault();

        const errors = this.validate();
        if (errors.length > 0) {
            this.setState({ errors: errors });
            return;
        } else {
            this.setState({ errors: [] });
        }

        const tableIds = this.state.tables.map(t => t.value);

        // date_time_from: this.state.reservation.date_time_from,
        // place_id: this.state.reservation.place_id,
        // room_id: this.state.reservation.room_id,
        // people_count: this.state.reservation.people_count,
        // name: this.state.reservation.name,
        // phone: this.state.reservation.phone
        // console.log('this.props.passedHallId',this.props.passedHallId);
        // return;
        const post = {
            reservationDate: this.state.reservation.date_time_from,
            date: this.state.reservation.date_time_from.toISOString().split('T')[0],
            time: this.state.reservation.date_time_from.toTimeString().split(' ')[0],
            // place_id: 62,
            // room_id: (Auth.getUserPlace() == 359 || Auth.getUserPlace() == 62) ? 1 : this.state.reservation.room_id,
            // room_id: this.props.passedHallId,
            people_count: this.state.reservation.people_count,
            // restaurantHallId: (Auth.getUserPlace() == 359 || Auth.getUserPlace() == 62) ? 1 : '',
            currentRestaurantHallId: this.props.passedHallId,
            place: Auth.getUserPlace(),//this.state.reservation.place_id,
            client_name: this.state.reservation.client_name,
            client_phone: this.state.reservation.client_phone,
            description: this.state.reservation.description,
            menu_included: this.state.reservation.menu_included,
            paid_ammount: this.state.reservation.paid_ammount,
            is_admin: 1,
        }

        //         name
        // phone
        // date
        // reservationDate
        // time
        // peopleCount
        // restaurantHallId
        // place

        let formData = new FormData();
console.log('formData',formData);
        for (var key in post) {
            formData.append(key, post[key]);
        }
        console.log('tableIds',tableIds);
        formData.append('tables', JSON.stringify(tableIds))

        // console.log(form_data);return;
        // formData.append('date_time_from', resData.date_time_from)
        // formData.append('people_count', resData.people_count)
        // formData.append('room_id', resData.room_id)
        // formData.append('name', resData.name)
        // formData.append('phone', resData.phone)

        this.props.addReservation(formData);
    }

    render() {
        // console.log("STATE ", this.state)
        // console.log("PROPS HERE ", this.props)
        let k = 0;
        let errorFields = this.state.errors.map(e => {
            return (
                <div key={k++} className="alert alert-danger alert-dismissible fade show">
                    {e}
                </div>
            )
        })

        return (
            <main className="main">

                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>Начало</NavLink></li>
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL + "reservations"}>Резервации</NavLink></li>
                    <li className="breadcrumb-item active">Добавяне</li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            {/* <!-- /.col--> */}
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">

                                        {this.props.successMsg ?
                                            <div className="alert alert-success  fade show" role="alert">
                                                {this.props.successMsg}
                                            </div>
                                            : ''}

                                        {this.props.errors && this.props.errors.length > 0 ?
                                            <div className="alert alert-danger  fade show" role="alert">
                                                {this.props.errors}
                                                <button className="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                                            </div>
                                            : ''}

                                        {this.state.errors && this.state.errors.length > 0 ? errorFields : null}


                                        {/* {errorFields} */}

                                        <ReservationForm
                                            onSubmit={this.onSubmit}
                                            onChange={this.onChange}
                                            onChangeCheckbox={this.onChangeCheckbox}
                                            onPlaceChange={this.onPlaceChange}
                                            allPlaces={this.props.allPlaces}
                                            allRooms={this.props.allRooms}
                                            rooms={this.state.rooms}
                                            allTables={this.props.tables}
                                            addTable={this.addTable}
                                            addTables={this.addTables}
                                            removeTable={this.removeTable}
                                            onTableSelectChange={this.onTableSelectChange}
                                            tables={this.state.tables}
                                            passedTableId={this.props.passedTableId}
                                            passedHallId={this.props.passedHallId}
                                            dateTimeForRes={this.props.dateTimeForRes}
                                            reservation={this.state.reservation}
                                            onReservationChange={this.onReservationChange}
                                            isAdding={1}
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    // place_id=1&hour=9&minutes=0&table_id=43&room_id=44
    let tableId = ownProps.match.params.id;
    let hallId = ownProps.match.params.hallId;
    let dateTimeForRes = ownProps.match.params.date || null;
    // console.log('hallId',hallId);
    return {
        // errors_form: undefined,
        errors: state.reservations.errors || undefined,
        successMsg: state.reservations.successMsg || undefined,

        allRooms: [],
        allPlaces: [],
        // allTables: [],
        // allTables: state.reservationTables.tables.records || [],
        tables: state.tables.tables,

        passedTableId: tableId,
        passedHallId: hallId,
        dateTimeForRes: dateTimeForRes,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ addReservation, listReservationTables, listTables }, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddReservationPage);

// export default connect(mapStateToProps, { addReservation, listReservationTables })(AddReservationPage);